import React, { useEffect } from "react";
import "./CheezosFoot.css";
import logo from "../assets/Cheezos Logo 1.svg";
import Location from "@material-ui/icons/LocationOn";
import Phone from "@material-ui/icons/Phone";
import { Clock, Facebook, Instagram, Mail } from "react-feather";
import { Link, useLocation } from "react-router-dom";

export default function CheezosFoot() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location]);

  return (
    <div className="CheezosFoot">
      <div className="Foot">
        <div className="foot-icon">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="Reach-Out">
          <h4> Reach Out</h4>
          <div className="Add-Row">
            {" "}
            <p className="Footer-Address">
              <Location/>
              E4/68, E-4, Arera Colony, Bhopal, Madhya Pradesh- 462016.
            </p>
          </div>
          <div className="Add-Row">
            {" "}
            <a href="tel:+91 7049027777">
              <Phone />
              +91 7049027777
            </a>
          </div>
          <div className="MailUsAt">
            <Mail />
            <a className="About-Us-Mail" href="mailto:thecheezos1@gmail.com">
            thecheezos1@gmail.com{" "}
            </a>
          </div>
          <div className="Add-Row">
            <p className="Timings">
              <Clock /> Timings: 10AM - 11PM
            </p>
          </div>
          <div className="foot-Contact-row">
            <div className="foot-Contact-row"></div>
            <div className="Footer-Social-Media">
              <a
                href="https://m.facebook.com/The-Cheezos-114197131264623/"
                target="_blank"
                rel="noreferrer"
              >
                <Facebook />
              </a>
              <a
                href="https://www.instagram.com/thecheezos/"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram />
              </a>
            </div>
          </div>
        </div>
        <div className="foot-row">
          <h4>Quick Links</h4>
          <div className="QuickLinks">
            <Link to="/">Home</Link>

            <Link to="/NewMenu">Menu</Link>

            <Link to="/aboutUs">About Us</Link>
            <Link to="/Franchise">Franchise</Link>
            <Link to="/feedback">Contact Us</Link>
          </div>
        </div>
        <div className="FooterM">
          <h4>Location</h4>
          <iframe
            title="Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.700280929546!2d77.4323597649714!3d23.217590984854677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c43a99bc65f07%3A0xc3953080d73545fd!2sThe%20Cheezos!5e0!3m2!1sen!2sin!4v1648534985030!5m2!1sen!2sin"
            allowFullscreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <div className="TechJain-Credits">
        <span></span>
        <h4>
          Design & Developed By{" "}
          <a href="https://techjain.com/" target="_blank" rel="noreferrer">
            TechJain
          </a>{" "}
          IT Solutions
        </h4>
      </div>
    </div>
  );
}
