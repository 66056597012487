
import React,{useState , useEffect} from "react";
import DropDown from "../Menu-dropdown/DropDown";
import Data,{type} from '../../Data'
const Categories = ({ categories, filterItems, activeCategory,setMenuItems }) => {
  const [filter, setFilter] = useState({category:'',type:''})
  useEffect(() => {
   
    setMenuItems(Data.filter((x) => x.category === filter.category && x.type === filter.type ))
    scrollToTargetAdjusted("ID-Scroll")
    return () => {
      
    }
  }, [filter])
  
  return (
    <div className="btn-container">
      {categories.map((category, index) => {
        return (
          <DropDown
            label={category}
            links={type(category)}
            setState={setFilter}
            className={`${
              activeCategory === category ? "filter-btn active" : "filter-btn"
            }`}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default Categories;
function scrollToTargetAdjusted(id){
  var element = document.getElementById(id);
  var headerOffset = 100;
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
       top: offsetPosition,
       behavior: "smooth"
  });
}