import React from 'react'
import './Gallery.css'
import verticalslider01 from '../assets/verticalslide01.avif'
import verticalslider02 from '../assets/verticalslide02.jpg'
import verticalslider03 from '../assets/verticalslide03.jpg'
import verticalslider04 from '../assets/verticalslide04.jpg'
import verticalslider05 from '../assets/verticalslide05.jpg'
import verticalslider06 from '../assets/verticalslide06.jpg'
import verticalslider07 from '../assets/verticalslide07.jpeg'
import verticalslider08 from '../assets/verticalslide08.jpg'
import verticalslider09 from '../assets/verticalslide09.jpg'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import gallerycarousel01 from '../assets/gallerycarousel01.jpg'
import gallerycarousel02 from '../assets/gallerycarousel02.jpg'
import gallerycarousel03 from '../assets/gallerycarousel03.jpg'
export default function Gallery() {
  return (
    <div className='Gallery-Container'>
        <div className="Gallery-Header">
            <span></span>
            <h2>Our Gallery</h2>
            <span></span>
        </div>
        <div className="All-Sliders">
            <div className="Vertical-Slider">
                <div className="Slider-Track">
                    <div className="Slide"><img src={verticalslider01} alt="" /></div>
                    <div className="Slide"><img src={verticalslider02} alt="" /></div>
                    <div className="Slide"><img src={verticalslider03} alt="" /></div>
                    <div className="Slide"><img src={verticalslider04} alt="" /></div>
                    <div className="Slide"><img src={verticalslider05} alt="" /></div>
                    <div className="Slide"><img src={verticalslider06} alt="" /></div>
                    <div className="Slide"><img src={verticalslider07} alt="" /></div>
                    <div className="Slide"><img src={verticalslider08} alt="" /></div>
                    <div className="Slide"><img src={verticalslider09} alt="" /></div>
                </div>
            </div>

            <div className="Slider-Carousel">
                <Carousel
                 autoPlay={true}
                 showThumbs={false}
                 infiniteLoop={true}
                 interval={2000}
                 showArrows={false}
                 showStatus={false}
                 preventMovementUntilSwipeScrollTolerance={true} >
                        <div className="Slide-Carousel">
                            <img src={gallerycarousel01} alt="" />
                        </div>
                        <div className="Slide-Carousel">
                            <img src={gallerycarousel02 } alt="" />
                        </div>
                        <div className="Slide-Carousel">
                            <img src={gallerycarousel03} alt="" />
                        </div>
                </Carousel>
            </div>

            <div className="Vertical-Slider">
                <div className="Slider-Track">
                    <div className="Slide"><img src={verticalslider01} alt="" /></div>
                    <div className="Slide"><img src={verticalslider02} alt="" /></div>
                    <div className="Slide"><img src={verticalslider03} alt="" /></div>
                    <div className="Slide"><img src={verticalslider04} alt="" /></div>
                    <div className="Slide"><img src={verticalslider05} alt="" /></div>
                    <div className="Slide"><img src={verticalslider06} alt="" /></div>
                    <div className="Slide"><img src={verticalslider07} alt="" /></div>
                    <div className="Slide"><img src={verticalslider08} alt="" /></div>
                    <div className="Slide"><img src={verticalslider09} alt="" /></div>
                </div>
            </div>
        </div>
    </div>
  )
}
