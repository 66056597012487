import React from "react";
import "./TodaysMenu.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import margheritapizza from '../assets/margheritapizzaslide.png'
import mushroompizza from '../assets/mushroompizzaslide.jpg'
import chikenpizza from '../assets/chikentikkapizzaslide.jpg'
import Garlicbread from '../menuassets/pasta.jpg'
import paneerbread from '../assets/paneergarlicbreadslide.jpg'
import classicbread from '../assets/calzone.jpg'
import spinachwrap from '../assets/spinachrollslide.jpg'
import chickenwrap from '../assets/chickenwrapslide.jpg'
import chickenwings from '../assets/chickenwingsslide.jpg'
export default function TodaysMenu() {
  return (
    <div className="TodaysMenu">
      <div className="TodaysMenu-Header">
        <h2>Our Specialities</h2>
        <span></span>
      </div>
      <div className="Menu-Carousel">
        <Carousel
          autoPlay={true}
          showThumbs={false}
          infiniteLoop={true}
          interval={2000}
          showArrows={true}
          showStatus={false}
          preventMovementUntilSwipeScrollTolerance={true}
        >
          <div className="Menu-Slides">
            <div className="Upward-Card">
              <div className="Upward-Image">
                <img src={margheritapizza} alt="" />
              </div>
              <div className="Upward-Content">
                <h3>Margherita Pizza</h3>
                <span></span>
                <p>
                  A simple pizza with minimal ingredients that’s still able to
                  create a mouthful of heaven with a crispy crust, creamy
                  mozzarella.
                </p>
              </div>
            </div>
            <div className="Downward-Card">
              <div className="Downward-Content">
                <h3>Mushroom Pizza</h3>
                <span></span>
                <p>
                  This mushroom pizza features savoury mushrooms,gooey cheese,black olives finished off with a olive oil that infuses the
                  entire pizza.
                </p>
              </div>
              <div className="Downward-Image">
                <img src={mushroompizza } alt="" />
              </div>
            </div>

            <div className="Upward-Card">
              <div className="Upward-Image">
                <img src={chikenpizza} alt="" />
              </div>
              <div className="Upward-Content">
                <h3>Chicken Pizza</h3>
                <span></span>
                <p>
                  Cheesy Indian pizza topped with delicious chicken tikka masala
                  and spicy indian flavours Along with the tangy dips.
                </p>
              </div>
            </div>
          </div>
          <div className="Menu-Slides">
            <div className="Upward-Card">
              <div className="Upward-Image">
                <img src={Garlicbread} alt="" />
              </div>
              <div className="Upward-Content">
                <h3>Gourmet Pasta  </h3>
                <span></span>
                <p>
                  Gourmet garlic butter spread over garlic bread slices for a
                  simple yet delightful experience.
                </p>
              </div>
            </div>
            <div className="Downward-Card">
              <div className="Downward-Content">
                <h3> Garlic Bread</h3>
                <span></span>
                <p>
                  Herbed paneer cubes along with veggies layered inside garlic
                  bread dough with cheese.
                </p>
              </div>
              <div className="Downward-Image">
                <img src={paneerbread } alt="" />
              </div>
            </div>

            <div className="Upward-Card">
              <div className="Upward-Image">
                <img src={classicbread} alt="" />
              </div>
              <div className="Upward-Content">
                <h3>Calzone</h3>
                <span></span>
                <p>
                  Fresh-from-the-garden flavors steal the show in this
                  mouthwatering Garlic Bread.
                </p>
              </div>
            </div>
          </div>
          <div className="Menu-Slides">
            <div className="Upward-Card">
              <div className="Upward-Image">
                <img src={spinachwrap} alt="" />
              </div>
              <div className="Upward-Content">
                <h3>Spinach Corn Wrap</h3>
                <span></span>
                <p>
                  Tortilla XL Wraps loaded with fresh green veggies, lettuce,
                  pickle & respective core fillings.
                </p>
              </div>
            </div>
            <div className="Downward-Card">
              <div className="Downward-Content">
                <h3>Chicken Wrap</h3>
                <span></span>
                <p>
                Chicken roll or Chicken Wrap is straight from the streets of Indian cities.
                </p>
              </div>
              <div className="Downward-Image">
                <img src={chickenwrap} alt="" />
              </div>
            </div>

            <div className="Upward-Card">
              <div className="Upward-Image">
                <img src={chickenwings} alt="" />
              </div>
              <div className="Upward-Content">
                <h3>Chicken Wings</h3>
                <span></span>
                <p>
                  Deep frying makes them crispy, to be sure, but it obliterates
                  the nuanced flavor of the skin.
                </p>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}
