import React from "react";
import "./Offer.css";
import promo_slide_1 from "../assets/promo_slide_1.jpg";
import promo_slide_3 from "../assets/promo_slide_3.jpg";
import promotions_area_bg_3 from "../assets/promotions_area_bg_3.jpg";
import offerslide from '../assets/Exotic Musroom Pizza.jpg'
import offerslide01 from '../assets/Peppry Corn Pizza.jpg'
import offerslide02 from '../assets/offerslide02.jpg'
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
export default function Offer() {
  return (
    <div className="Offer">
      <div className="head">
        <h2>Special Package</h2>
        <span></span>
      </div>
      <div className="offer">
        <div className="Offer-Carousel">
          <Carousel  autoPlay={true}
          showThumbs={false}
          infiniteLoop={true}
          interval={2000}
          showArrows={false}
          showStatus={false}
          preventMovementUntilSwipeScrollTolerance={true}>
            <div className="Offer-Slide">
              <img src={offerslide02} alt="" />
            </div>
            <div className="Offer-Slide">
              <img src={offerslide01} alt="" />
            </div>
            <div className="Offer-Slide">
              <img src={offerslide} alt="" />
            </div>
          </Carousel>
        </div>
        <div className="OfferCard">
          <div className="offer-card">
            <div class="package-price">20%</div>
            {/* <h3>40% off for 9'' - 12'' pizza - Wed, Thu, Friday only.</h3> */}
            <h3>Get flat 20%* off on pre-booked birthday, kitty parties & events.

</h3>
            <p>
              *Minimum 10 people.
            </p>

            <a href='https://thrivenow.in/thecheezos' target='_blank' rel="noreferrer" >Order now</a>
          </div>
        </div>
      </div>
    </div>
  );
}
