import React, { useState, useEffect } from "react";
import "./App.css";
import CheezosFoot from "./components/footer/CheezosFoot";
import Home from "./components/Home/Home";
import Navbar from "./components/Navbar/Navbar.jsx";
import { Routes, Route } from "react-router-dom";
import Menu from "./components/Menu/Menu";

import AboutUs from "./components/AboutUs/AboutUs";
import SplashScreen from "./components/SplashScreen/SplashScreen";
import Gallery from "./components/GalleryPage/Gallery";
import Feedback from "./components/Feedback/Feedback.jsx";
import SocialMedia from "./components/SocialMediaBar/SocialMedia";
import HomeMenu from "./components/Homemenu/HomeMenu";
import Getmenu from "./components/getmenu/Getmenu";
import FranchisePage from "./components/FranchisePage/FranchisePage";
import FormTrigger from "./components/Franchise-Form-Trigger/Form-Trigger";
import NewMenu from "./components/newmenu/NewMenu";
import Capsule from "./components/CategoryCapsule/Capsule";
import HeadingCapsule from "./components/CapsuleHeading/HeadingCapsule";
function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <div className="App">
      {loading ? (
        <SplashScreen />
      ) : (
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/menu" element={<Menu />} />

            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/HomeMenu" element={<HomeMenu />} />
            <Route path="/Franchise" element={<FranchisePage />} />
            <Route path="/NewMenu" element={<NewMenu />} />
            <Route path="/Capsule" element={<Capsule />} />
            <Route path="/HeadingCapsule" element={<HeadingCapsule />} />
          </Routes>
          <SocialMedia />
          <FormTrigger />
          <Getmenu />
          <CheezosFoot />
        </>
      )}
    </div>
  );
}

export default App;
