import React from "react";

import "./SocialMedia.css";
import { Link } from "react-router-dom";

import { Facebook, Instagram, MessageSquare } from "react-feather";
export default function SocialMedia() {
  return (
    <div className="SocialMedia-Feedback">
      <div className="Icon-Bar">
        <a
          className="Facebook"
          href="https://m.facebook.com/The-Cheezos-114197131264623/"
          target={"_blank"}
          rel="noreferrer"
        >
        <Facebook/>

        </a>

        <a
          href="https://instagram.com/thecheezos?igshid=YmMyMTA2M2Y="
          target={"_blank"}
          rel="noreferrer"
          className="Instagram"
        >
         <Instagram/>
        </a>
        <Link to="/feedback" className="FeedBack">
          <MessageSquare/>
        </Link>
      </div>
    </div>
  );
}
