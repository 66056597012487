import React from "react";
import "./Feedback.css";
export default function Feedback() {
  function submit() {
    alert("Thank You for valuable Feedback..");
  }
  const HandleSubmit = (e) => {
    e.preventDefault();
    const scriptURL = `https://v1.nocodeapi.com/thecheezos/google_sheets/WqJmrFomHDfeABAv?tabId=contactus`;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const form = document.forms["Feedback-Form"];
    let data = new FormData(form);
    let row = [
      [
        data.get("Name"),
        data.get("Email"),
        data.get("Mobile No"),
        data.get("Feedback"),
      ],
    ];

    console.log(row);
    var requestOptions = {
      method: "post",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(row),
    };

    //console.log(data.get("name"));
    fetch(scriptURL, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        alert("Your Query Was Sent Successfully!");
        window.location.reload();
      })
      .catch((error) => {
        alert("Failed to send your query.!");
        console.log("error", error);
      });
  };
  return (
    <div className="Feedback">
      <div className="feedback-form">
        <form action="submit" onSubmit={HandleSubmit} name="Feedback-Form">
          <div className="Form-Col">
            <div className="Feedback-Header">
              <h2>Get in touch with us!</h2>
              <span></span>
            </div>
            <div className="Form-row">
              <label htmlFor="Name"></label>
              <input
                type="text"
                required
                name="Name"
                id="01"
                placeholder="Name"
              />
            </div>
            <div className="Form-row">
              <label htmlFor="Mobile No"></label>
              <input
                type="text"
                required
                name="Mobile No"
                id="01"
                placeholder="Mobile No"
              />
            </div>
            <div className="Form-row">
              <label htmlFor="email"></label>
              <input
                type="email"
                required
                name="Email"
                id="01"
                placeholder="Email"
              />
            </div>
            <div className="Form-row">
              <textarea
                name="Feedback"
                id=""
                cols="80"
                rows="9"
                placeholder="Message"
                required
              ></textarea>
            </div>
            <div className="Form-row">
              <button className="Form-btn">Submit</button>
            </div>
          </div>
        </form>
      </div>
      <div className="ContactUs-Details">
        <div className="Contact-Details">
          <div className="Contact-Details-Content">
            <h2>Contact Details</h2>
            <p>
              Mobile No: +917049027777
              <br />
              TelePhone No: +917049027777
              <br />
              Email: thecheezos1@gmail.com
              <br />
              <br />
              <br />
              <span>Address</span>
              <br />
              E4/68, E-4, Arera Colony, Bhopal,
              <br />
              Madhya Pradesh 462016
            </p>
          </div>
          <div className="Map-ContactUs">
            <iframe
              title="Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.700280929546!2d77.4323597649714!3d23.217590984854677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c43a99bc65f07%3A0xc3953080d73545fd!2sThe%20Cheezos!5e0!3m2!1sen!2sin!4v1648534985030!5m2!1sen!2sin"
              allowFullscreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
