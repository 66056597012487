import HotChocolateBrownie from "./components/menuassets/Hot Chocolate Brownie.jpg";
import KidsPizzaCombo from "./components/menuassets/Kids Pizza Combo.jpg";
import juice01 from "./components/assets/juice01.png";
import juice02 from "./components/assets/juice02.png";
import juice03 from "./components/assets/juice03.png";
import juice04 from "./components/assets/juice04.png";
import juice05 from "./components/assets/juice05.png";
import juice06 from "./components/assets/juice06.png";
import juice07 from "./components/assets/juice07.png";
import juice08 from "./components/assets/juice08.png";
import juice09 from "./components/assets/juice09.png";
import juice10 from "./components/assets/juice10.png";
import juice11 from "./components/assets/juice11.png";
import juice12 from "./components/assets/juice12.png";
import juice13 from "./components/assets/juice13.png";
import juice14 from "./components/assets/juice14.png";
import juice15 from "./components/NewmenuAssets/Berries Blast.jpg";
import juice16 from "./components/NewmenuAssets/Chocolate Shake.jpg";
import regularnachos from "./components/assets/salsanachos.png";
import fries from "./components/assets/smallfries.png";
import regularfries from "./components/assets/regularfries.png";
import periperifries from "./components/assets/peripericheesefries.png";
import falafel from "./components/assets/falafel.png";
import potatowedges from "./components/assets/potatowedges.png";
import potatowedges2 from "./components/assets/potatowedges2.png";
import cheesefries from "./components/assets/cheesefries01.png";
import cheesefries2 from "./components/assets/cheesefries02.png";
import cheesefries3 from "./components/assets/cheesefries03.png";
import wings from "./components/assets/wings.png";
// ----------------------PASTA---------------------------//
import ASP from "./components/assets2/Pasta/ASP.jpg";
import BCS from "./components/assets2/Pasta/BCS.jpg";
import MCSP from "./components/assets2/Pasta/MCSP.jpg";
import MSP from "./components/assets2/Pasta/MSP.jpg";
// ------------------------------------------------------//
// ----------------------Pizza-----------------------//
import margherita from "./components/assets2/Pizza/Cheeseonly pizza/Margherita Pizza.png";
import tre from "./components/assets2/Pizza/Cheeseonly pizza/Tre Cheese Pizza.png";
import quatro from "./components/assets2/Pizza/Cheeseonly pizza/Quatro Cheese Pizza.png";
import CC from "./components/assets2/Pizza/Veggie pizaa/Corn & Capsicum Pizza.png";
import GHP from "./components/assets2/Pizza/Veggie pizaa/Garden House Pizza.png";
import CSP from "./components/assets2/Pizza/Veggie pizaa/Country Side Pizza.png";
import PCP from "./components/assets2/Pizza/Veggie pizaa/Peppry Corn Pizza.png";
import EXM from "./components/assets2/Pizza/Veggie pizaa/Exotic Mushroom Pizza.png";
import VSP from "./components/assets2/Pizza/Veggie pizaa/Veg Supreme Pizza.png";
import POP from "./components/assets2/Pizza/paneer pizza/paneer & onion pizza.jpg";
import PTP from "./components/assets2/Pizza/paneer pizza/paneer tikka pizza.jpg";
import STP from "./components/assets2/Pizza/paneer pizza/spice & tango Paneer pizza.jpg";
import EPP from "./components/assets2/Pizza/paneer pizza/Exotic Paneer Pizza.jpeg";
import PSP from "./components/assets2/Pizza/paneer pizza/paneer supreme pizza.jpg";
import CTP from "./components/assets2/Pizza/chicken pizza/Chicken Tikka Pizza.png";
import CKP from "./components/assets2/Pizza/chicken pizza/chicken kheemapizza.jpg";
import CAP from "./components/assets2/Pizza/chicken pizza/chicken afghani pizza.jpg";
import PPCP from "./components/assets2/Pizza/chicken pizza/Peri Peri Chicken Pizza.png";
import ECP from "./components/assets2/Pizza/chicken pizza/Exotic Chicken Pizza.png";
import COP from "./components/assets2/Pizza/chicken pizza/Chicken Overload Pizza.png";
import OnionP from "./components/assets2/Pizza/UNO Pizza/Onion Pizza.png";
import TOMP from "./components/assets2/Pizza/UNO Pizza/Tomato Pizza.png";
import CAPP from "./components/assets2/Pizza/UNO Pizza/Capsicum Pizza.png";
import CornP from "./components/assets2/Pizza/UNO Pizza/Corn Pizza.jpeg";
import MushP from "./components/assets2/Pizza/UNO Pizza/Mushroom Pizza.jpg";
// -------------------------------------------------------------//
import pizza03 from "./components/NewmenuAssets/Pizza07.png";
import pizza04 from "./components/NewmenuAssets/Pizza08.png";
import pizza05 from "./components/NewmenuAssets/Pizza09.png";
import pizza06 from "./components/NewmenuAssets/Pizza10.png";
import pizza07 from "./components/NewmenuAssets/Pizza11.png";
import pizza08 from "./components/NewmenuAssets/Pizza12.png";
import pizza09 from "./components/NewmenuAssets/Pizza13.png";
import pizza10 from "./components/NewmenuAssets/Pizza14.png";
import pizza11 from "./components/NewmenuAssets/Pizza15.png";
import pizza12 from "./components/NewmenuAssets/Pizza16.png";
import pizza13 from "./components/NewmenuAssets/Pizza17.png";
import pizza14 from "./components/NewmenuAssets/Pizza18.png";
import pizza15 from "./components/NewmenuAssets/Pizza19.png";
import pizza16 from "./components/NewmenuAssets/Pizza20.png";
import pizza17 from "./components/NewmenuAssets/Pizza21.png";
import pizza18 from "./components/NewmenuAssets/Pizza21.png";
import pizza19 from "./components/NewmenuAssets/Pizza21.png";
import pizza20 from "./components/NewmenuAssets/Pizza21.png";
import pizza22 from "./components/NewmenuAssets/P22.png";
import pizza23 from "./components/NewmenuAssets/P23.png";
import pizza25 from "./components/NewmenuAssets/Capsicum.png";
import pizza24 from "./components/NewmenuAssets/UNOCOrn.png";
import pizza21 from "./components/NewmenuAssets/Pizza21.png";
// ------------------------Garlic-Breads--------------------//
import Garlic1 from "./components/assets2/garlicbread/garlic bread.jpg";
import Garlic2 from "./components/assets2/garlicbread/Garlic Breadsticks.jpg";
import Garlic3 from "./components/assets2/garlicbread/cheese garlic bread.jpg";
import Garlic4 from "./components/assets2/garlicbread/Spicy Special Garlic Bread.png";
//----------------------------------------------------------------------------------//

//---------------------------Stuffed Garlic Bread---------------------------------//

import Garlic5 from "./components/assets2/garlicbread/veggies delight.jpg";
import Garlic6 from "./components/assets2/garlicbread/paneer delight.jpg";
import Garlic7 from "./components/assets2/garlicbread/Exotic Mushroom.png";
import Garlic8 from "./components/assets2/garlicbread/minced chicken.jpg";

// --------------------------------------------------------//
//-----------------Wraps-----------------------------------//
import CCW from "./components/assets2/Wraps/CCW.jpg";
import VegRW from "./components/assets2/New/veg fresh wrap.jpg";
import MXVW from "./components/assets2/Wraps/MXVW.jpg";
import PPPW from "./components/assets2/Wraps/PPPW.jpg";
import CTW from "./components/assets2/Wraps/CTW.jpg";
import CSW from "./components/assets2/Wraps/CSW.jpg";
import SCW from "./components/assets2/New/spinach corn wrap.jpg"
import PRW from "./components/assets2/Wraps/PPPW.jpg";
// ---------------------------------------------------------//
//----------------------Rolls-----------------------------//
import VFR from "./components/assets2/Rolls/VFR.jpg";
import PTMR from "./components/assets2/Rolls/PTMR.jpg";
import PMR from "./components/assets2/Rolls/PMR.jpg";
import CTMR from "./components/assets2/Rolls/CTMR.jpg";
import CKR from "./components/assets2/Rolls/CKR.jpg";
import CKR2 from "./components/assets2/Rolls/CKR2.jpg";
//---------------------------------------------------------//

//----------------------Munchies------------------------//
import smallfries from "./components/assets2/Munchies/French Fries Small.jpg";
import regfries from "./components/assets2/Munchies/French Fries Regular.png";
import Vegfalafel from "./components/assets2/Munchies/Veg Falafel Kebab.jpg";

//------------------------------------------------------//

//---------------------------Quencher--------------------------//
import Lemo from "./components/assets2/Quenchers/5- star Mojitos ( non- alcoholic)/Lemo Fizz.png";
import Passion from "./components/assets2/Quenchers/5- star Mojitos ( non- alcoholic)/Passion Punch 1.jpg";
import Seashore from "./components/assets2/Quenchers/5- star Mojitos ( non- alcoholic)/Seashore 1.jpg";
import HB from "./components/assets2/Quenchers/5- star Moctails ( non- alcoholic)/Hawaian Blue.png";
import TS1 from "./components/assets2/Quenchers/5- star Moctails ( non- alcoholic)/Tropical Sunset 1.png";
import minty from "./components/assets2/Quenchers/5- star Moctails ( non- alcoholic)/Minty Berries.jpeg";
import twisted from "./components/assets2/Quenchers/5- star Moctails ( non- alcoholic)/Twisited Berries.jpg";
import mm from "./components/assets2/Quenchers/5- star Mojitos ( non- alcoholic)/mint mojito.jpg";
//--------------------------------------------------------------//

//--------------------------Coolest Shakes------------------------//

import MM from "./components/assets2/Quenchers/Coolest shakes/MANGO MOJO.jpg";
import SFM from "./components/assets2/Quenchers/Coolest shakes/SITAFAL SHAKES.jpg";
import SBM from "./components/assets2/Quenchers/Coolest shakes/STRAWBERRY MOJO.jpg";
import CHShakes from "./components/assets2/Quenchers/Coolest shakes/CHOCOLATE SHAKES.jpg";
import BB from "./components/assets2/Quenchers/Coolest shakes/BERRIES BLAST.jpg";
import Oreo from "./components/assets2/Quenchers/Coolest shakes/OREO SHAKES.jpg";

//-----------------------------------------------------------------//

//-------------------------Sweet Tooth------------------------------//
import CWB from "./components/assets2/Sweettooth/Chocolate Walnut Brownie.jpg";
import WB from "./components/assets2/Sweettooth/Walnut Brownie with Icecream.jpeg";
//------------------------------------------------------------------//

// -------------------------------Cold coffee-----------------------------//
   import C from "./components/assets2/New/cold coffee1.jpg"
   import CCoffee from "./components/assets2/New/cold.jpg"
//------------------------------------------------------------------------//

//-------------------------------Cheese fries---------------------------//
 import icf from "./components/assets2/New/italian cheese fries.jpg"
 import dcf from"./components/assets2/New/dragon cheese fries.jpg"
 import ppf from "./components/assets2/New/peri peri cheese fries.jpg"

//-----------------------------------------------------------------------//
//--------------------------------chicken snacks------------------------//
import Chick from "./components/assets2/New/cripsy chicken wings.jpg"
//-----------------------------------------------------------------------//
//------------------------------Fries------------------------------------//

import pwedges from "./components/assets2/New/mwedges.jpg"
import mwedges from"./components/assets2/New/mwedges.jpg"
import pfries from "./components/assets2/New/peri peri fries.jpeg"


//----------------------------Combo Meals-----------------------------//

import KPC from "./components/assets2/kidscombo/kidz pizza combi -dine-in.jpg";
import VPM1 from "./components/assets2/kidscombo/Veg pizza meal 1.jpg";
import VPM2 from "./components/assets2/kidscombo/Veg pizza meal 2.jpg";
import VPM4 from "./components/assets2/kidscombo/Veg pizza meal 4.jpg";

//--------------------------------------------------------------------//

//---------------------------Nachos----------------------------------//
import salsa from "./components/assets2/New/salsanachos.jpg"
import justn from "./components/assets2/New/JUST NACHOS.jpg"

//------------------------------------------------------------------//

//------------------------------Chicken-pizza-meal---------------------//
import CPM from "./components/assets2/Chickenpizzameal/chicken pizza  meal for 1.jpg";
import CPM2 from "./components/assets2/Chickenpizzameal/chicken pizza  meal for 2.jpg";
import CPM4 from "./components/assets2/Chickenpizzameal/chicken pizza  meal for 3.jpg";

//---------------------------------------------------------------------//

import W1 from "./components/figmadesign/W1.png";
import W2 from "./components/figmadesign/W2.png";
import W3 from "./components/figmadesign/W3.png";
import W4 from "./components/figmadesign/W4.png";
import W5 from "./components/figmadesign/W5.png";
import W6 from "./components/figmadesign/W6.png";
import W7 from "./components/figmadesign/W7.png";
import W8 from "./components/figmadesign/W8.png";
import W9 from "./components/figmadesign/W9.png";
import W10 from "./components/figmadesign/W10.png";
import W11 from "./components/figmadesign/W11.png";
import W12 from "./components/figmadesign/W12.png";
import W13 from "./components/figmadesign/W13.png";
import W14 from "./components/figmadesign/W14.png";
import St1 from "./components/figmadesign/ST1.png";
import CM1 from "./components/figmadesign/CM1.png";
import { PushPinTwoTone } from "@mui/icons-material";

const Newmenu = [
  {
    Category: "Pizzeria",
    SubCat: [
      {
        name: "Cheeze Only Pizza",
        menu: [
          {
            id: 1,
            title: "Margherita Pizza",
            Regular: "119",
            Medium: "199",
            img: margherita,
            desc: `Gourmet pizza marinara sauce spread over handcrafted
                    dough & topped with mozzarella cheese`,
          },
          {
            id: 2,
            title: "Tre Cheese Pizza",
            Regular: "159",
            Medium: "329",
            img: tre,
            desc: `Love triangle of mozzarella , cheddar & special herb cheese on a handcrafted pizza base. `,
          },
          {
            id: 3,
            title: "Quatro Cheese Pizza",
            Medium: "349",
            img: quatro,
            desc: `Fantastic four topping of mozzarella , cheddar , special herbs & pepper cheese is a must try for an exotic cheesy experience. `,
          },
        ],
      },
      {
        name: "Veggies Pizza",
        menu: [
          {
            id: 4,
            title: "Corn & Capsicum Pizza",
            Regular: "139",
            Medium: "239",
            img: CC,
            desc: `A flavorsome combination of delicious golden corn ,green capsicum , mozzarella cheese & handcrafted base `,
          },
          {
            id: 5,
            title: "Garden House Pizza",

            Regular: "139",
            Medium: "239",
            img: GHP,
            desc: `A refreshing topping of veggies "onion, tomato, corn & capsicum"  & mozzarella cheese over a handcrafted base`,
          },
          {
            id: 5,
            title: "CountrySide Pizza",
            Regular: "149",
            Medium: "259",
            img: CSP,
            desc: `A delightful combination of mushroom, bell pepper
                ,onion, capsicum ,corn,mozzarella cheese spread over a
                handcrafted base`,
          },
          {
            id: 6,
            title: "Peppery Corn Pizza",
            Regular: "149",
            Medium: "259",
            img: PCP,
            desc: `Feel the Mexican wave with capsicum, bell Pepper, jalapeno, red paprika, corn loaded on a handcrafted base with mozarella cheese`,
          },
          {
            id: 7,
            title: "Exotic Mushroom Pizza",
            Regular: "169",
            Medium: "289",
            img: EXM,
            desc: `An exotic cheesy handcrafted pizza topped with mushrooms, corn, olive & red paprika for a delightful pizza experience. `,
          },
          {
            id: 8,
            title: "Veg Supreme Pizza",
            Regular: "299",
            img: VSP,
            desc: `Loaded veggies "bell pepper, olive, corn, capsicum, tomato, onion, jalapeno" & mozarella cheese spread over a handcrafted base. `,
          },
        ],
      },
      {
        name: "Paneer Pizza",
        menu: [
          {
            id: 9,
            title: "Paneer & Onion Pizza",
            category: "Pizzeria",
            type: "Paneer Pizza",
            Regular: "149",
            Medium: "259",
            img: POP,
            desc: `Gourmet marinara sauce layered over a handcrafted base & topped with Indian cottage cheese cubes & onion.  `,
          },
          {
            id: 10,
            title: "Paneer Tikka Pizza",
            category: "Pizzeria",
            type: "Paneer Pizza",
            Regular: "159",
            Medium: "289",
            img: PTP,
            desc: `Paneer seasoned with tikka masala along with onion, tomato & capsicum toppings is one of the most loved fusion pizza. `,
          },
          {
            id: 11,
            title: "Spicy & Tango Pizzeria",
            category: "Pizzeria",
            type: "Paneer Pizza",
            Regular: "159",
            Medium: "289",
            img: STP,
            desc: `I'm baby woke mlkshk wolf bitters live-edge blue bottle, hammock freegan copper mug whatever cold-pressed `,
          },
          {
            id: 12,
            title: "Exotic Paneer Pizza",
            category: "Pizzeria",
            type: "Paneer Pizza",
            Regular: "169",
            Medium: "299",
            img: EPP,
            desc: `An exotic cheesy handcrafted pizza topped with paneer, corn, olive & red paprika for a delightful experience `,
          },
          {
            id: 13,
            title: "Paneer Supreme Pizza",
            category: "Pizzeria",
            type: "Paneer Pizza",
            Regular: "329",
            img: PSP,
            desc: `Loaded with herbed paneer,bell pepper, corn, capsicum, tomato, onion, olive, red paprika,it is a supreme treat to have. `,
          },
        ],
      },
      {
        name: "Chicken Pizza",
        menu: [
          {
            id: 14,
            title: "Chicken Tikka Pizza",
            category: "Pizzeria",
            type: "Chicken Pizza",
            Regular: "169",
            Medium: "299",
            img: CTP,
            desc: `Gourmet sesoned chicken along with onion, capsicum and red paprika topped over a handcrafted base is for tikka lovers. `,
          },
          {
            id: 15,
            title: "Chicken Kheema Pizza",
            category: "Pizzeria",
            type: "Chicken Pizza",
            Regular: "169",
            Medium: "299",
            img: CKP,
            desc: `Gourmet chicken kheema along with onion & capsicum spread over a handcrafted base is a treat you can't miss. `,
          },
          {
            id: 16,
            title: "Chicken Afghani Pizza",
            category: "Pizzeria",
            type: "Chicken Pizza",
            Regular: "169",
            Medium: "299",
            img: CAP,
            desc: `Chicken chunks marinated in afghani seasoning along with onion, capsicum topped over a handcrafted base. `,
          },
          {
            id: 17,
            title: "Peri Peri Pizzeria",
            category: "Pizzeria",
            type: "Chicken Pizza",
            Regular: "179",
            Medium: "329",
            img: PPCP,
            desc: `Gourmet sesoned peri-peri chicken , red paprika & mozaarella cheese spread over handcrafted base is for those who love extra spice in life.`,
          },
          {
            id: 18,
            title: "Exotic Chicken Pizzeria",
            category: "Pizzeria",
            type: "Chicken Pizza",
            Regular: "179",
            Medium: "329",
            img: ECP,
            desc: `An awesome combination of chicken & mushroom with olive, corn, jalapeno & cheese is something which gives a divine experience.`,
          },
          {
            id: 19,
            title: "Chicken Overload Pizza",
            category: "Pizzeria",
            type: "Chicken Pizza",
            Regular: "349",
            img: COP,
            desc: `Chicken chicken & only chicken!! A pizza loaded with varities of chicken toppings for die hard chicken lovers `,
          },
        ],
      },
      {
        name: "UNO Pizza",
        menu: [
          {
            id: 20,
            title: "Onion Pizza",

            category: "Pizzeria",
            type: "UNO Pizza",
            Regular: 99,
            img: OnionP,
            desc: `Onions should be pan sautéed with a little olive oil until soft and caramelized before putting on top of a pizza. `,
          },
          {
            id: 21,
            title: "Tomato Pizza",
            category: "Pizzeria",
            type: "UNO Pizza",
            Regular: 99,
            img: TOMP,
            desc: `Actually, the best tomatoes for pizza sauce is considered to be San Marzano Tomatoes. `,
          },
          {
            id: 22,
            title: "Capsicum Pizza",
            category: "Pizzeria",
            type: "UNO Pizza",
            Regular: 99,
            img: CAPP,
            desc: `Capsicum pizza is an Italian dish that is popular for its crispy crust, tomato sauce, and mild capsicum flavour. `,
          },
          {
            id: 23,
            title: "Corn Pizza",
            category: "Pizzeria",
            type: "UNO Pizza",
            Regular: 99,
            img: CornP,
            desc: `Prepared using pizza dough, sauce, corn, mozzarella, parmesan, and cheddar cheese, this recipe is a pizza lover’s dream come true.`,
          },
          {
            id: 24,
            title: "Mushroom Pizza",
            category: "Pizzeria",
            type: "UNO Pizza",
            Regular: 109,
            img: MushP,
            desc: `This mushroom pizza is a white pizza that’s covered with mozzarella cheese, goat cheese, and fresh herbs. It’s perfection!`,
          },
        ],
      },
    ],
  },

  {
    Category: "Garlic Bread",
    SubCat: [
      {
        name: "Garlic Bread",
        menu: [
          {
            id: 25,
            title: "Garlic Bread",
            category: "Garlic Bread",
            type: "Garlic Bread",
            Regular: 99,
            img: Garlic1,
            desc: `Classic toasted Garlic bread topped with gourmet
                    garlic butter with mild flavor of parsle.`,
          },
          {
            id: 26,
            title: "Garlic Breadsticks",
            category: "Garlic Bread",
            type: "Garlic Bread",
            Regular: 99,
            img: Garlic2,
            desc: `Fresh baked garlic bread sticks filled with mild garlic
                    butter & cheese spread.`,
          },
          {
            id: 27,
            title: "Cheese Garlic Bread",
            category: "Garlic Bread",
            type: "Garlic Bread",
            Regular: 129,
            img: Garlic3,
            desc: `Garlic bread topped with gourmet garlic butter with
                    mild flavor of parsle & cheese, baked in oven is a classic
                    treat to have`,
          },
          {
            id: 28,
            title: "Spicy Special Garlic Bread",
            category: "Garlic Bread",
            type: "Garlic Bread",
            Regular: 149,
            img: Garlic4,
            desc: `Garlic bread topped with fresh chopped spicy veggies, gourmet garlic butter & cheese.`,
          },
        ],
      },
      {
        name: "Stuffed Garlic Bread",
        menu: [
          {
            id: 29,
            title: "Veggies Delight Garlic Bread",
            category: "Garlic Bread",
            type: "Stuffed Garlic Bread",
            Regular: 149,
            img: Garlic5,
            desc: `Fresh baked garlic bread sticks filled with pizza veggies & cheese.`,
          },
          {
            id: 30,
            title: "Paneer Delight Garlic Bread",
            category: "Garlic Bread",
            type: "Stuffed Garlic Bread",
            Regular: 159,
            img: Garlic6,
            desc: `Herbed paneer cubes along with veggies layered inside garlic bread dough with cheese`,
          },
          {
            id: 31,
            title: "Exotic Mushroom Stuffed Garlic Bread",
            category: "Garlic Bread",
            type: "Stuffed Garlic Bread",
            Regular: 149,
            img: Garlic7,
            desc: `An exotic mix of mushroom, corn, olive tossed with garlic butter & filled inside the garlic bread dough with cheese is an exotic experience to have. `,
          },
          {
            id: 32,
            title: "Minced Chicken Stuffed Garlic Bread",
            category: "Garlic Bread",
            type: "Stuffed Garlic Bread",
            Regular: 169,
            img: Garlic8,
            desc: `Gourmet minced chicken filled inside the garlic bread dough with cheese is a treat for a fusion food lover.`,
          },
        ],
      },
    ],
  },
  {
    Category: "Pasta",
    SubCat: [
      {
        name: "Red Sauce Pasta",
        menu: [
          {
            id: 33,
            title: "Arrabbiata Sauce Pasta",
            category: "Pasta",
            type: "Red Sauce Pasta",
            Regular: 149,
            img: ASP,
            desc: `Veggies & penne pasta in red arrabbiata spicy gourmet sauce for spicy pasta lovers.`,
          },
          {
            id: 34,
            title: "Bechamel Cream Sauce Pasta",
            category: "Pasta",
            type: "Red Sauce Pasta",
            Regular: 149,
            img: BCS,
            desc: `Veggies & penne pasta in classic bechamel cream white sauce for cream n cheese lovers.`,
          },

          {
            id: 35,
            title: "Marinara Sauce Pasta",
            category: "Pasta",
            type: "Red Sauce Pasta",
            Regular: 149,
            img: MSP,
            desc: `Veggies & penne pasta cooked in gourmet marinara sauce is an amazing pasta to have.`,
          },
          {
            id: 36,
            title: "Marinara Cream Sauce Pasta",
            category: "Pasta",
            type: "Red Sauce Pasta",
            Regular: 149,
            img: MCSP,
            desc: ` Veggies & penne pasta in gourmet red marinara cream pink sauce for a great tangy n creamy experience.`,
          },
        ],
      },
    ],
  },
  {
    Category: "Flat-Breads",
    SubCat: [
      {
        name: "Tortilla Wraps",
        menu: [
          {
            id: 37,
            title: "Veg Refresh Wrap",
            category: "Flat-Breads",
            type: "Tortilla Wraps",
            Regular: 119,
            img: VegRW,
            desc: `Delicious and healthy veg refresh wrap with a thin layer of your favourite veggies`,
          },
          {
            id: 38,
            title: "Spinach Corn Wrap",
            category: "Flat-Breads",
            type: "Tortilla Wraps",
            Regular: 119,
            img: SCW,
            desc: `Delicious spinach corn wrap with a thin layer of your green and healthy spinach `,
          },
          {
            id: 39,
            title: "Mexican Veg Wrap",
            category: "Flat-Breads",
            type: "Tortilla Wraps",
            Regular: 129,
            img: MXVW,
            desc: `Want to eat some mexican desi try our very own mexican veg wrap`,
          },
          {
            id: 40,
            title: "Cheese Corn Wrap",
            category: "Flat-Breads",
            type: "Tortilla Wraps",
            Regular: 129,
            img: CCW,
            desc: `Cheese corn wrap is loaded with cheese with the topping of fresh sweet corn`,
          },
          {
            id: 41,
            title: "Paneer Refresh Wrap",
            category: "Flat-Breads",
            type: "Tortilla Wraps",
            Regular: 139,
            img: PRW,
            desc: `Paneer refresh wrap is loaded with fresh paneer and veggies`,
          },
          {
            id: 42,
            title: "Peri Peri Paneer Wrap",
            category: "Flat-Breads",
            type: "Tortilla Wraps",
            Regular: 139,
            img: PPPW,
            desc: `Peri-Peri paneer wrap is a wrap that is made using peri peri sauces`,
          },
          {
            id: 43,
            title: "Chicken Tikka Wrap",
            category: "Flat-Breads",
            type: "Tortilla Wraps",
            Regular: 139,
            img: CTW,
            desc: `A treat for non-vegetarians our very own chicken tikka wrap `,
          },
          {
            id: 44,
            title: "Chicken Supreme Wrap",
            category: "Flat-Breads",
            type: "Tortilla Wraps",
            Regular: 139,
            img: CSW,
            desc: `Quench your hunger on the first go try our very own chicken supreme wrap`,
          },
        ],
      },
      {
        name: "Lachha Rolls",
        menu: [
          {
            id: 45,
            title: "Veg Falafel Roll",
            category: "Flat-Breads",
            type: "Lachha Rolls",
            Regular: 119,
            img: VFR,
            desc: `Falafel roll is a combination of falafel balls,hummus and mixed green salad`,
          },
          {
            id: 46,
            title: "Paneer Tawa Masala Roll",
            category: "Flat-Breads",
            type: "Lachha Rolls",
            Regular: 119,
            img: PTMR,
            desc: `Paneer tawa masala roll is a combination of fresh paneer well fried`,
          },
          {
            id: 47,
            title: "Paneer Mughlai Roll",
            category: "Flat-Breads",
            type: "Lachha Rolls",
            Regular: 119,
            img: PMR,
            desc: `Want to eat something mughlai try our very own Paneer Mughlai Roll `,
          },
          {
            id: 48,
            title: "Chicken Tikka Masala Roll",
            category: "Flat-Breads",
            type: "Lachha Rolls",
            Regular: 139,
            img: CTMR,
            desc: `Want to eat a non veg roll try our very own Chicken tikka masala roll`,
          },
          {
            id: 49,
            title: "Chicken Bhuna Keema Roll",
            category: "Flat-Breads",
            Regular: 139,
            type: "Lachha Rolls",
            img: CKR,
            desc: `How's Bhuna Keema feel "Mouthwatering" try our very own Chicken Bhuna Keema Roll`,
          },
          {
            id: 50,
            title: "Chicken Kofta Masala Roll",
            category: "Flat-Breads",
            type: "Lachha Rolls",
            Regular: 139,
            img: CKR2,
            desc: `Lets have some kofta feel in roll style try our very own Chicken Kofta Masala Roll`,
          },
        ],
      },
    ],
  },
  {
    Category: "Munchies",
    SubCat: [
      {
        name: "Nachos",
        menu: [
          {
            id: 51,
            title: "Just Nachos",
            category: "Munchies",
            type: "Just Nachos",
            Regular: 99,
            img: justn,
            desc: ` Nachos are a mexican food consisting of fried tortilla chips`,
          },
          {
            id: 52,
            title: "Salsa Nachos",
            category: "Munchies",
            type: "Salsa Nachos",
            Regular: 119,
            img: salsa,
            desc: ` a tortilla chip topped with melted cheese and often additional savory toppings`,
          },
        ],
      },
      // {
      //   name: "Salsa Nachos",
      //   menu: [
      //     {
      //       id: 52,
      //       title: "Salsa Nachos",
      //       category: "Munchies",
      //       type: "Salsa Nachos",
      //       Regular: 119,
      //       img: salsa,
      //       desc: ` a tortilla chip topped with melted cheese and often additional savory toppings`,
      //     },
      //   ],
      // },
      {
        name: "Fries",
        menu: [
          {
            id: 53,
            title: "Small Fries",
            category: "Munchies",
            type: "Fries",
            Regular: 49,
            img: smallfries,
            desc: `Small hunger....Quench that ..try Small fries`,
          },
          {
            id: 54,
            title: "Regular Fries",
            category: "Munchies",
            type: "Fries",
            Regular: 79,
            img: regfries,
            desc: `Want to eat something regular yet super tasty try our very own regular fries`,
          },
          {
            id: 55,
            title: "Peri Peri Fries",
            category: "Munchies",
            type: "Fries",
            Regular: 99,
            img: pfries,
            desc: `Fries With peri peri sauce try it fast at our very own shop`,
          },
          {
            id: 56,
            title: "Veg Falafel",
            category: "Munchies",
            type: "Fries",
            Regular: 79,
            img: Vegfalafel,
            desc: `Fries in the taste of your favourite falafel ..try it`,
          },
          {
            id: 57,
            title: "Masala Wedges",
            category: "Munchies",
            type: "Fries",
            Regular: 109,
            img: mwedges,
            desc: `Masala Wedges are crispy crust and fluffy inside..try it`,
          },
          {
            id: 58,
            title: "Peri Peri Wedges",
            category: "Munchies",
            type: "Fries",
            Regular: 119,
            img: pwedges,
            desc: `Peri peri masala flavoured wedges are tantalizing..try it`,
          },
        ],
      },
      {
        name: "Cheese Fries",
        menu: [
          {
            id: 59,
            title: "Italian Cheese Fries",
            category: "Munchies",
            type: "Cheese Fries",
            Regular: 129,
            img: icf,
            desc: `Want to try some italian with desi touch try our very own Italian cheese fries`,
          },
          {
            id: 60,
            title: "Dragon Cheese Fries",
            category: "Munchies",
            type: "Cheese Fries",
            Regular: 129,
            img: dcf,
            desc: `Dragon cheese fries are super tasty as it is made up of using Dragon cheese`,
          },
          {
            id: 61,
            title: "Peri Peri Cheese Fries",
            category: "Munchies",
            type: "Cheese Fries",
            Regular: 129,
            img: ppf,
            desc: `Peri peri cheese fries has super amazing taste try it `,
          },
        ],
      },
      {
        name: "Chicken Snacks",
        menu: [
          {
            id: 62,
            title: "Crispy Chicken Wings",
            category: "Munchies",
            type: "Chicken Snacks",
            Regular: 149,
            img: Chick,
            desc: `A thin strip of potato, usually cut 3 to 4 inches in length and about 1/4 to 3/8 inches.`,
          },
        ],
      },
    ],
  },
  {
    Category: "Quencher",
    SubCat: [
      {
        name: "5 Star Mojitos (Non Alcoholic)",
        menu: [
          {
            id: 63,
            title: "Lemo Fizz",
            category: "Quencher",
            type: "5 Star Mojitos (Non Alcoholic) ",
            Regular: 49,
            img: Lemo,
            desc: `The mojito is the epitome of the refreshing cocktail, stripped down to just the bare essentials of rum`,
          },
          {
            id: 64,
            title: "Mint Mojito",
            category: "Quencher",
            type: "5 Star Mojitos (Non Alcoholic) ",
            Regular: 69,
            img: mm,
            desc: `The mint mojito is the epitome of the refreshing cocktail,try it`,
          },
          {
            id: 65,
            title: "Passion Punch",
            category: "Quencher",
            type: "5 Star Mojitos (Non Alcoholic) ",
            Regular: 69,
            img: Passion,
            desc: `Passion punch is made up of passion fruit gin and lime juice..give it a try`,
          },
          {
            id: 66,
            title: "Seashore",
            category: "Quencher",
            type: "5 Star Mojitos (Non Alcoholic) ",
            Regular: 69,
            img: Seashore,
            desc: `Seashore mojito is a refreshing mojito give it a try`,
          },
          {
            id: 70,
            title: "Minty Berries",
            category: "Quencher",
            type: "5 Star Mojitos (Non Alcoholic) ",
            Regular: 69,
            img: minty,
            desc: `The minty berries cocktail is made up of fresh berries`,
          },
        ],
      },
      // {
      //   name: "5 Star Mocktail (Non Alcoholic)",
      //   menu: [
      //     {
      //       id: 67,
      //       title: "Twisted Berries",
      //       category: "Quencher",
      //       type: "5 Star Mocktail (Non Alcoholic) ",
      //       Regular: 69,
      //       img: twisted,
      //       desc: `This mojito is made up of twisted berries and some other cool stuff try it....`,
      //     },

      //     {
      //       id: 68,
      //       title: "Hawaiian Blue",
      //       category: "Quencher",
      //       type: "5 Star Mocktail (Non Alcoholic) ",
      //       Regular: 89,
      //       img: HB,
      //       desc: `The hawaaian blue mojito is blue in color and so you can think like how cool is that. Try it....`,
      //     },
      //     {
      //       id: 69,
      //       title: "Tropical Sunset",
      //       category: "Quencher",
      //       type: "5 Star Mocktail (Non Alcoholic) ",
      //       Regular: 89,
      //       img: TS1,
      //       desc: `The tropical sunset is a layered cocktail with cocnut rum, pineapple juice`,
      //     },
      //     {
      //       id: 70,
      //       title: "Minty Berries",
      //       category: "Quencher",
      //       type: "5 Star Mojitos (Non Alcoholic) ",
      //       Regular: 89,
      //       img: minty,
      //       desc: `The minty berries cocktail is made up of fresh berries`,
      //     },
      //   ],
      // },
      {
        name: "Super Star Cold Coffees",
        menu: [
          {
            id: 71,
            title: "Cold Coffee",
            category: "Quencher",
            type: "Super Star Cold Coffees",
            Regular: 89,
            img: C,
            desc: `Super Star Cold Coffees`,
          },
          {
            id: 72,
            title: "Cold Coffee with Ice Cream",
            category: "Quencher",
            type: "Super Star Cold Coffees",
            Regular: 119,
            img: CCoffee,
            desc: `Super Star Cold Coffees`,
          },
        ],
      },
      {
        name: "Coolest Shakes",
        menu: [
          {
            id: 73,
            title: "Mango Mojo",
            category: "Quencher",
            type: "Coolest Shakes",
            Regular: 89,
            img: MM,
            desc: `A Mango mojo is a sweet drink made by blending milk with mango and ice cream,`,
          },
          {
            id: 74,
            title: "Sitafal Mojo",
            category: "Quencher",
            type: "Coolest Shakes",
            Regular: 89,
            img: SFM,
            desc: `A Sitafal mojo is a sweet drink made by blending sitafal fruit with milk, ice cream,`,
          },
          {
            id: 75,
            title: "Strawberry Mojo",
            category: "Quencher",
            type: "Coolest Shakes",
            Regular: 89,
            img: SBM,
            desc: `A Strawberry mojo is a sweet drink made by blending strawberry with milk, ice cream,`,
          },
          {
            id: 76,
            title: "Chocolate",
            category: "Quencher",
            type: "Coolest Shakes",
            Regular: 89,
            img: CHShakes,
            desc: `A chocolate is a sweet drink made by blending milk,with chocolate and ice cream,`,
          },
          {
            id: 77,
            title: "Oreo",
            category: "Quencher",
            type: "Coolest Shakes",
            Regular: 89,
            img: Oreo,
            desc: `As the name suggests Orio is drink  made by blending milk with orio and , ice cream,`,
          },
          {
            id: 78,
            title: "Berries Blast",
            category: "Quencher",
            type: "Coolest Shakes",
            Regular: 89,
            img: BB,
            desc: `A berries blast is a sweet drink made by blending berries with milk, ice cream,`,
          },
        ],
      },
    ],
  },
  {
    Category: "Sweet Tooth",
    SubCat: [
      {
        name: "Hot Chocolate Brownie(Eggless)",
        menu: [
          {
            id: 79,
            title: "Hot Chocolate Brownie",
            category: "Sweet Tooth",
            type: "Hot Chocolate Brownie(Eggless)",
            Regular: 79,
            img: CWB,
            desc: `Our hot chocolate is made of brownies, home made chocolate.`,
          },
          {
            id: 79,
            title: "Brownie with icecream",
            category: "Sweet Tooth",
            type: "Hot Chocolate Brownie(Eggless)",
            Regular: 79,
            img: WB,
            desc: `Our hot chocolate is made of brownies, home made chocolate sauce and vanilla ice-cream.`,
          },
        ],
      },
    ],
  },
  {
    Category: "Combos",
    SubCat: [
      {
        name: "Kids Pizza Combo",
        menu: [
          {
            id: 80,
            title: "Kids Pizza Combo",
            category: "Combos",
            type: "Kids Pizza Combo",
            Regular: 129,
            img: KPC,
            desc: `4 in 1 Regular Pizza
                    (1Pcs) + Small Fries
                    + Mazza/Amul Tru`,
          },
        ],
      },
      {
        name: "Veg Pizza Meal Combos",
        menu: [
          {
            id: 81,
            title: "Veg Pizza Meal for 1",
            category: "Combos",
            type: "Veg Pizza Meal Combos",
            Regular: 225,
            img: VPM1,
            desc: `Any Regular Veg Pizza
                    2 Pcs Garlic Bread
                    1 Coke, 1 Cup Ice Cream`,
          },
          {
            id: 81,
            title: "Veg Pizza Meal for 2",
            category: "Combos",
            type: "Veg Pizza Meal Combos",
            Regular: 425,
            img: VPM2,
            desc: `Any Veg Medium Pizza
                    4 Pcs Garlic Bread
                    2 Coke, 2 Cup Ice Cream`,
          },
          {
            id: 81,
            title: "Veg Pizza Meal for 4",
            category: "Combos",
            type: "Veg Pizza Meal Combos",
            Regular: 725,
            img: VPM4,
            desc: `Any 2 Medium Pizza
                    8 Pcs Garlic Bread
                    4 Coke, 4 Cup Ice Cream`,
          },
        ],
      },
      {
        name: "Chicken Pizza Meal Combos",
        menu: [
          {
            id: 82,
            title: "Chicken Pizza Meal for 1",
            category: "Combos",
            type: "Chicken Pizza Meal Combos",
            Regular: 259,
            img: CPM,
            desc: `Any Regular Non Veg Pizza
                2 Pcs Garlic Bread
                1 Coke, 1 Cup Ice Cream`,
          },
          {
            id: 82,
            title: "Chicken Pizza Meal for 2",
            category: "Combos",
            type: "Chicken Pizza Meal Combos",
            Regular: 479,
            img: CPM2,
            desc: `Any Non Veg Medium Pizza
                4 Pcs Garlic Bread
                2 Coke, 2 Cup Ice Cream`,
          },
          {
            id: 82,
            title: "Chicken Pizza Meal for 4",
            category: "Combos",
            type: "Chicken Pizza Meal Combos",
            Regular: 799,
            img: CPM4,
            desc: `Any 2 Medium Pizza
                8 Pcs Garlic Bread
                4 Coke, 4 Cup Ice Cream`,
          },
        ],
      },
    ],
  },
];
export default Newmenu;
