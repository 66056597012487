import HotChocolateBrownie from "./components/menuassets/Hot Chocolate Brownie.jpg";
import KidsPizzaCombo from "./components/menuassets/Kids Pizza Combo.jpg";
import juice01 from "./components/assets/juice01.png";
import juice02 from "./components/assets/juice02.png";
import juice03 from "./components/assets/juice03.png";
import juice04 from "./components/assets/juice04.png";
import juice05 from "./components/assets/juice05.png";
import juice06 from "./components/assets/juice06.png";
import juice07 from "./components/assets/juice07.png";
import juice08 from "./components/assets/juice08.png";
import juice09 from "./components/assets/juice09.png";
import juice10 from "./components/assets/juice10.png";
import juice11 from "./components/assets/juice11.png";
import juice12 from "./components/assets/juice12.png";
import juice13 from "./components/assets/juice13.png";
import juice14 from "./components/assets/juice14.png";
import regularnachos from "./components/assets/salsanachos.png";
import fries from "./components/assets/smallfries.png";
import regularfries from "./components/assets/regularfries.png";
import periperifries from "./components/assets/peripericheesefries.png";
import falafel from "./components/assets/falafel.png";
import potatowedges from "./components/assets/potatowedges.png";
import potatowedges2 from "./components/assets/potatowedges2.png";
import cheesefries from "./components/assets/cheesefries01.png";
import cheesefries2 from "./components/assets/cheesefries02.png";
import cheesefries3 from "./components/assets/cheesefries03.png";
import wings from "./components/assets/wings.png";
import pasta01 from "./components/NewmenuAssets/Pasta01.png";
import pasta02 from "./components/NewmenuAssets/Pasta02.png";
import pasta03 from "./components/NewmenuAssets/Pasta03.png";
import pasta04 from "./components/NewmenuAssets/Pasta04.png";
// ----------------------Pizza-----------------------//
import margherita from "./components/assets2/Pizza/Cheeseonly pizza/margherita  pizza.jpg";
import tre from "./components/assets2/Pizza/Cheeseonly pizza/tre.jpg";
// ----------------------Pizza-----------------------//
import pizza03 from "./components/NewmenuAssets/Pizza07.png";
import pizza04 from "./components/NewmenuAssets/Pizza08.png";
import pizza05 from "./components/NewmenuAssets/Pizza09.png";
import pizza06 from "./components/NewmenuAssets/Pizza10.png";
import pizza07 from "./components/NewmenuAssets/Pizza11.png";
import pizza08 from "./components/NewmenuAssets/Pizza12.png";
import pizza09 from "./components/NewmenuAssets/Pizza13.png";
import pizza10 from "./components/NewmenuAssets/Pizza14.png";
import pizza11 from "./components/NewmenuAssets/Pizza15.png";
import pizza12 from "./components/NewmenuAssets/Pizza16.png";
import pizza13 from "./components/NewmenuAssets/Pizza17.png";
import pizza14 from "./components/NewmenuAssets/Pizza18.png";
import pizza15 from "./components/NewmenuAssets/Pizza19.png";
import pizza16 from "./components/NewmenuAssets/Pizza20.png";
import pizza17 from "./components/NewmenuAssets/Pizza21.png";
import pizza18 from "./components/NewmenuAssets/Pizza21.png";
import pizza19 from "./components/NewmenuAssets/Pizza21.png";
import pizza20 from "./components/NewmenuAssets/Pizza21.png";
import pizza22 from "./components/NewmenuAssets/P22.png";
import pizza23 from "./components/NewmenuAssets/P23.png";
import pizza25 from "./components/NewmenuAssets/Capsicum.png";
import pizza24 from "./components/NewmenuAssets/UNOCOrn.png";
import pizza21 from "./components/NewmenuAssets/Pizza21.png";
import bread01 from "./components/NewmenuAssets/Bread01.png";
import bread02 from "./components/NewmenuAssets/Bread02.png";
import bread03 from "./components/NewmenuAssets/Bread03.png";
import bread04 from "./components/NewmenuAssets/Bread04.png";
import bread05 from "./components/NewmenuAssets/Bread05.png";
import bread06 from "./components/NewmenuAssets/Bread06.png";
import bread07 from "./components/NewmenuAssets/Bread07.png";
import bread08 from "./components/NewmenuAssets/Bread08.png";
import W1 from "./components/figmadesign/W1.png";
import W2 from "./components/figmadesign/W2.png";
import W3 from "./components/figmadesign/W3.png";
import W4 from "./components/figmadesign/W4.png";
import W5 from "./components/figmadesign/W5.png";
import W6 from "./components/figmadesign/W6.png";
import W7 from "./components/figmadesign/W7.png";
import W8 from "./components/figmadesign/W8.png";
import W9 from "./components/figmadesign/W9.png";
import W10 from "./components/figmadesign/W10.png";
import W11 from "./components/figmadesign/W11.png";
import W12 from "./components/figmadesign/W12.png";
import W13 from "./components/figmadesign/W13.png";
import W14 from "./components/figmadesign/W14.png";
import St1 from "./components/figmadesign/ST1.png";
import CM1 from "./components/figmadesign/CM1.png";

const menu = [
  {
    id: 1,
    title: "Margherita Pizza",
    category: "Pizzeria",
    type: "CheezeOnly Pizza",
    Regular: "119",
    Medium: "199",
    img: margherita,
    desc: `Gourmet pizza marinara sauce spread over handcrafted
    dough & topped with mozzarella cheese`,
  },
  {
    id: 2,
    title: "Tre Cheese Pizza",
    category: "Pizzeria",
    type: "CheezeOnly Pizza",
    Regular: "159",
    Medium: "329",
    img: tre,
    desc: `Love triangle of mozzarella , cheddar & special herb cheese on a handcrafted pizza base. `,
  },
  {
    id: 3,
    title: "Quatro Cheese Pizza",
    category: "Pizzeria",
    type: "CheezeOnly Pizza",
    Medium: "349",
    img: pizza03,
    desc: `Fantastic four topping of mozzarella , cheddar , special herbs & pepper cheese is a must try for an exotic cheesy experience. `,
  },
  {
    id: 4,
    title: "Corn & Capsicum Pizza",
    category: "Pizzeria",
    type: "Veggies Pizza",
    Regular: "139",
    Medium: "239",
    img: pizza04,
    desc: `A flavorsome combination of delicious golden corn ,green capsicum , mozzarella cheese & handcrafted base `,
  },
  {
    id: 5,
    title: "Garden House Pizza",
    category: "Pizzeria",
    type: "Veggies Pizza",
    Regular: "139",
    Medium: "239",
    img: pizza05,
    desc: `A refreshing topping of veggies "onion, tomato, corn & capsicum"  & mozzarella cheese over a handcrafted base`,
  },
  {
    id: 5,
    title: "CountrySide Pizza",
    category: "Pizzeria",
    type: "Veggies Pizza",
    Regular: "149",
    Medium: "259",
    img: pizza05,
    desc: `A delightful combination of mushroom, bell pepper
    ,onion, capsicum ,corn,mozzarella cheese spread over a
    handcrafted base`,
  },
  {
    id: 6,
    title: "Peppery Corn Pizza",
    category: "Pizzeria",
    type: "Veggies Pizza",
    Regular: "149",
    Medium: "259",
    img: pizza06,
    desc: `Feel the Mexican wave with capsicum, bell Pepper, jalapeno, red paprika, corn loaded on a handcrafted base with mozarella cheese`,
  },
  {
    id: 7,
    title: "Exotic Mushroom Pizza",
    category: "Pizzeria",
    type: "Veggies Pizza",
    Regular: "169",
    Medium: "289",
    img: pizza07,
    desc: `An exotic cheesy handcrafted pizza topped with mushrooms, corn, olive & red paprika for a delightful pizza experience. `,
  },
  {
    id: 8,
    title: "Veg Supreme Pizza",
    category: "Pizzeria",
    type: "Veggies Pizza",
    Regular: "299",
    img: pizza08,
    desc: `Loaded veggies "bell pepper, olive, corn, capsicum, tomato, onion, jalapeno" & mozarella cheese spread over a handcrafted base. `,
  },
  {
    id: 9,
    title: "Paneer & Onion Pizza",
    category: "Pizzeria",
    type: "Paneer Pizza",
    Regular: "149",
    Medium: "259",
    img: pizza09,
    desc: `Gourmet marinara sauce layered over a handcrafted base & topped with Indian cottage cheese cubes & onion.  `,
  },
  {
    id: 10,
    title: "Paneer Tikka Pizza",
    category: "Pizzeria",
    type: "Paneer Pizza",
    Regular: "159",
    Medium: "289",
    img: pizza10,
    desc: `Paneer seasoned with tikka masala along with onion, tomato & capsicum toppings is one of the most loved fusion pizza. `,
  },
  {
    id: 11,
    title: "Spicy & Tango Pizzeria",
    category: "Pizzeria",
    type: "Paneer Pizza",
    Regular: "159",
    Medium: "289",
    img: pizza11,
    desc: `I'm baby woke mlkshk wolf bitters live-edge blue bottle, hammock freegan copper mug whatever cold-pressed `,
  },
  {
    id: 12,
    title: "Exotic Paneer Pizza",
    category: "Pizzeria",
    type: "Paneer Pizza",
    Regular: "169",
    Medium: "299",
    img: pizza12,
    desc: `An exotic cheesy handcrafted pizza topped with paneer, corn, olive & red paprika for a delightful experience `,
  },
  {
    id: 13,
    title: "Paneer Supreme Pizza",
    category: "Pizzeria",
    type: "Paneer Pizza",
    Regular: "329",
    img: pizza13,
    desc: `Loaded with herbed paneer,bell pepper, corn, capsicum, tomato, onion, olive, red paprika,it is a supreme treat to have. `,
  },

  {
    id: 14,
    title: "Chicken Tikka Pizza",
    category: "Pizzeria",
    type: "Chicken Pizza",
    Regular: "169",
    Medium: "299",
    img: pizza14,
    desc: `Gourmet sesoned chicken along with onion, capsicum and red paprika topped over a handcrafted base is for tikka lovers. `,
  },
  {
    id: 15,
    title: "Chicken Kheema Pizza",
    category: "Pizzeria",
    type: "Chicken Pizza",
    Regular: "169",
    Medium: "299",
    img: pizza15,
    desc: `Gourmet chicken kheema along with onion & capsicum spread over a handcrafted base is a treat you can't miss. `,
  },
  {
    id: 16,
    title: "Chicken Afghani Pizza",
    category: "Pizzeria",
    type: "Chicken Pizza",
    Regular: "169",
    Medium: "299",
    img: pizza15,
    desc: `Chicken chunks marinated in afghani seasoning along with onion, capsicum topped over a handcrafted base. `,
  },
  {
    id: 17,
    title: "Peri Peri Pizzeria",
    category: "Pizzeria",
    type: "Chicken Pizza",
    Regular: "179",
    Medium: "329",
    img: pizza16,
    desc: `Gourmet sesoned peri-peri chicken , red paprika & mozaarella cheese spread over handcrafted base is for those who love extra spice in life.`,
  },
  {
    id: 18,
    title: "Exotic Chicken Pizzeria",
    category: "Pizzeria",
    type: "Chicken Pizza",
    Regular: "179",
    Medium: "329",
    img: pizza17,
    desc: `An awesome combination of chicken & mushroom with olive, corn, jalapeno & cheese is something which gives a divine experience.`,
  },
  {
    id: 19,
    title: "Chicken Overload Pizza",
    category: "Pizzeria",
    type: "Chicken Pizza",
    Regular: "349",
    img: pizza18,
    desc: `Chicken chicken & only chicken!! A pizza loaded with varities of chicken toppings for die hard chicken lovers `,
  },

  {
    id: 20,
    title: "Onion Pizza",

    category: "Pizzeria",
    type: "UNO Pizza",
    Regular: 99,
    img: pizza22,
    desc: `Onions should be pan sautéed with a little olive oil until soft and caramelized before putting on top of a pizza. `,
  },
  {
    id: 21,
    title: "Tomato Pizza",
    category: "Pizzeria",
    type: "UNO Pizza",
    Regular: 99,
    img: pizza23,
    desc: `Actually, the best tomatoes for pizza sauce is considered to be San Marzano Tomatoes. `,
  },
  {
    id: 22,
    title: "Capsicum Pizza",
    category: "Pizzeria",
    type: "UNO Pizza",
    Regular: 99,
    img: pizza25,
    desc: `Capsicum pizza is an Italian dish that is popular for its crispy crust, tomato sauce, and mild capsicum flavour. `,
  },
  {
    id: 23,
    title: "Corn Pizza",
    category: "Pizzeria",
    type: "UNO Pizza",
    Regular: 99,
    img: pizza24,
    desc: `Prepared using pizza dough, sauce, corn, mozzarella, parmesan, and cheddar cheese, this recipe is a pizza lover’s dream come true.`,
  },
  {
    id: 24,
    title: "Mushroom Pizza",
    category: "Pizzeria",
    type: "UNO Pizza",
    Regular: 109,
    img: pizza21,
    desc: `This mushroom pizza is a white pizza that’s covered with mozzarella cheese, goat cheese, and fresh herbs. It’s perfection!`,
  },

  {
    id: 25,
    title: "Garlic Bread",
    category: "Garlic Bread",
    type: "Garlic Bread",
    Regular: 99,
    img: bread01,
    desc: `Classic toasted Garlic bread topped with gourmet
    garlic butter with mild flavor of parsle.`,
  },
  {
    id: 26,
    title: "Garlic Breadsticks",
    category: "Garlic Bread",
    type: "Garlic Bread",
    Regular: 99,
    img: bread02,
    desc: `Fresh baked garlic bread sticks filled with mild garlic
    butter & cheese spread.`,
  },
  {
    id: 27,
    title: "Cheese Garlic Bread",
    category: "Garlic Bread",
    type: "Garlic Bread",
    Regular: 129,
    img: bread03,
    desc: `Garlic bread topped with gourmet garlic butter with
    mild flavor of parsle & cheese, baked in oven is a classic
    treat to have`,
  },
  {
    id: 28,
    title: "Spicy Special Garlic Bread",
    category: "Garlic Bread",
    type: "Garlic Bread",
    Regular: 149,
    img: bread04,
    desc: `Garlic bread topped with fresh chopped spicy veggies, gourmet garlic butter & cheese.`,
  },

  {
    id: 29,
    title: "Veggies Delight Garlic Bread",
    category: "Garlic Bread",
    type: "Stuffed Garlic Bread",
    Regular: 149,
    img: bread05,
    desc: `Fresh baked garlic bread sticks filled with pizza veggies & cheese.`,
  },
  {
    id: 30,
    title: "Paneer Delight Garlic Bread",
    category: "Garlic Bread",
    type: "Stuffed Garlic Bread",
    Regular: 159,
    img: bread06,
    desc: `Herbed paneer cubes along with veggies layered inside garlic bread dough with cheese`,
  },
  {
    id: 31,
    title: "Exotic Mushroom Stuffed Garlic Bread",
    category: "Garlic Bread",
    type: "Stuffed Garlic Bread",
    Regular: 149,
    img: bread07,
    desc: `An exotic mix of mushroom, corn, olive tossed with garlic butter & filled inside the garlic bread dough with cheese is an exotic experience to have. `,
  },
  {
    id: 32,
    title: "Minced Chicken Stuffed Garlic Bread",
    category: "Garlic Bread",
    type: "Stuffed Garlic Bread",
    Regular: 169,
    img: bread08,
    desc: `Gourmet minced chicken filled inside the garlic bread dough with cheese is a treat for a fusion food lover.`,
  },

  {
    id: 33,
    title: "Arrabbiata Sauce Pasta",
    category: "Pasta",
    type: "Red Sauce Pasta",
    Regular: 149,
    img: pasta01,
    desc: `Veggies & penne pasta in red arrabbiata spicy gourmet sauce for spicy pasta lovers.`,
  },
  {
    id: 34,
    title: "Bechamel Cream Sauce Pasta",
    category: "Pasta",
    type: "Red Sauce Pasta",
    Regular: 149,
    img: pasta02,
    desc: `Veggies & penne pasta in classic bechamel cream white sauce for cream n cheese lovers.`,
  },

  {
    id: 35,
    title: "Marinara Sauce Pasta",
    category: "Pasta",
    type: "Red Sauce Pasta",
    Regular: 149,
    img: pasta03,
    desc: `Veggies & penne pasta cooked in gourmet marinara sauce is an amazing pasta to have.`,
  },
  {
    id: 36,
    title: "Marinara Cream Sauce Pasta",
    category: "Pasta",
    type: "Red Sauce Pasta",
    Regular: 149,
    img: pasta04,
    desc: ` Veggies & penne pasta in gourmet red marinara cream pink sauce for a great tangy n creamy experience.`,
  },

  {
    id: 37,
    title: "Veg Refresh Wrap",
    category: "Flat-Breads",
    type: "Tortilla Wraps",
    Regular: 119,
    img: W1,
    desc: `Tortilla XL Wraps loaded with fresh green veggies, lettuce , pickle & respective core fillings`,
  },
  {
    id: 38,
    title: "Spinach Corn Wrap",
    category: "Flat-Breads",
    type: "Tortilla Wraps",
    Regular: 119,
    img: W2,
    desc: `Tortilla XL Wraps loaded with fresh green veggies, lettuce , pickle & respective core fillings`,
  },
  {
    id: 39,
    title: "Mexican Veg Wrap",
    category: "Flat-Breads",
    type: "Tortilla Wraps",
    Regular: 129,
    img: W3,
    desc: `Tortilla XL Wraps loaded with fresh green veggies, lettuce , pickle & respective core fillings`,
  },
  {
    id: 40,
    title: "Cheese Corn Wrap",
    category: "Flat-Breads",
    type: "Tortilla Wraps",
    Regular: 129,
    img: W4,
    desc: `Tortilla XL Wraps loaded with fresh green veggies, lettuce , pickle & respective core fillings`,
  },
  {
    id: 41,
    title: "Paneer Refresh Wrap",
    category: "Flat-Breads",
    type: "Tortilla Wraps",
    Regular: 139,
    img: W5,
    desc: `Tortilla XL Wraps loaded with fresh green veggies, lettuce , pickle & respective core fillings`,
  },
  {
    id: 42,
    title: "Peri Peri Paneer Wrap",
    category: "Flat-Breads",
    type: "Tortilla Wraps",
    Regular: 139,
    img: W6,
    desc: `Tortilla XL Wraps loaded with fresh green veggies, lettuce , pickle & respective core fillings`,
  },
  {
    id: 43,
    title: "Chicken Tikka Wrap",
    category: "Flat-Breads",
    type: "Tortilla Wraps",
    Regular: 139,
    img: W7,
    desc: `Tortilla XL Wraps loaded with fresh green veggies, lettuce , pickle & respective core fillings`,
  },
  {
    id: 44,
    title: "Chicken Supreme Wrap",
    category: "Flat-Breads",
    type: "Tortilla Wraps",
    Regular: 139,
    img: W8,
    desc: `Tortilla XL Wraps loaded with fresh green veggies, lettuce , pickle & respective core fillings`,
  },

  {
    id: 45,
    title: "Veg Falafel Roll",
    category: "Flat-Breads",
    type: "Lachha Rolls",
    Regular: 119,
    img: W9,
    desc: `Lachha rolls loaded with the respective gourmet masala fillings, veggies is a complete one-hand meal`,
  },
  {
    id: 46,
    title: "Paneer Tawa Masala Roll",
    category: "Flat-Breads",
    type: "Lachha Rolls",
    Regular: 119,
    img: W10,
    desc: `Lachha rolls loaded with the respective gourmet masala fillings, veggies is a complete one-hand meal`,
  },
  {
    id: 47,
    title: "Paneer Mughlai Roll",
    category: "Flat-Breads",
    type: "Lachha Rolls",
    Regular: 119,
    img: W11,
    desc: `Lachha rolls loaded with the respective gourmet masala fillings, veggies is a complete one-hand meal`,
  },
  {
    id: 48,
    title: "Chicken Tikka Masala Roll",
    category: "Flat-Breads",
    type: "Lachha Rolls",
    Regular: 139,
    img: W12,
    desc: `Lachha rolls loaded with the respective gourmet masala fillings, veggies is a complete one-hand meal`,
  },
  {
    id: 49,
    title: "Chicken Bhuna Keema Roll",
    category: "Flat-Breads",
    Regular: 139,
    type: "Lachha Rolls",
    img: W13,
    desc: `Lachha rolls loaded with the respective gourmet masala fillings, veggies is a complete one-hand meal`,
  },
  {
    id: 50,
    title: "Chicken Kofta Masala Roll",
    category: "Flat-Breads",
    type: "Lachha Rolls",
    Regular: 139,
    img: W14,
    desc: `Lachha rolls loaded with the respective gourmet masala fillings, veggies is a complete one-hand meal`,
  },

  {
    id: 51,
    title: "Just Nachos",
    category: "Munchies",
    type: " Nachos",
    Regular: 99,
    img: regularnachos,
    desc: ` a tortilla chip topped with melted cheese and often additional savory toppings`,
  },
  // {
  //   id: 52,
  //   title: "Salsa Nachos",
  //   category: "Munchies",
  //   type: "Salsa Nachos",
  //   Regular: 119,
  //   img: regularnachos,
  //   desc: ` a tortilla chip topped with melted cheese and often additional savory toppings`,
  // },

  {
    id: 53,
    title: "Small Fries",
    category: "Munchies",
    type: "Fries",
    Regular: 49,
    img: fries,
    desc: `A thin strip of potato, usually cut 3 to 4 inches in length and about 1/4 to 3/8 inches.`,
  },
  {
    id: 54,
    title: "Regular Fries",
    category: "Munchies",
    type: "Fries",
    Regular: 79,
    img: regularfries,
    desc: `A thin strip of potato, usually cut 3 to 4 inches in length and about 1/4 to 3/8 inches.`,
  },
  {
    id: 55,
    title: "Peri Peri Fries",
    category: "Munchies",
    type: "Fries",
    Regular: 99,
    img: periperifries,
    desc: `A thin strip of potato, usually cut 3 to 4 inches in length and about 1/4 to 3/8 inches.`,
  },
  {
    id: 56,
    title: "Veg Falafel",
    category: "Munchies",
    type: "Fries",
    Regular: 79,
    img: falafel,
    desc: `A thin strip of potato, usually cut 3 to 4 inches in length and about 1/4 to 3/8 inches.`,
  },
  {
    id: 57,
    title: "Masala Wedges",
    category: "Munchies",
    type: "Fries",
    Regular: 109,
    img: potatowedges,
    desc: `A thin strip of potato, usually cut 3 to 4 inches in length and about 1/4 to 3/8 inches.`,
  },
  {
    id: 58,
    title: "Peri Peri Wedges",
    category: "Munchies",
    type: "Fries",
    Regular: 119,
    img: potatowedges2,
    desc: `A thin strip of potato, usually cut 3 to 4 inches in length and about 1/4 to 3/8 inches.`,
  },

  {
    id: 59,
    title: "Italian Cheese Fries",
    category: "Munchies",
    type: "Cheese Fries",
    Regular: 129,
    img: cheesefries,
    desc: `A thin strip of potato, usually cut 3 to 4 inches in length and about 1/4 to 3/8 inches.`,
  },
  {
    id: 60,
    title: "Dragon Cheese Fries",
    category: "Munchies",
    type: "Cheese Fries",
    Regular: 129,
    img: cheesefries2,
    desc: `A thin strip of potato, usually cut 3 to 4 inches in length and about 1/4 to 3/8 inches.`,
  },
  {
    id: 61,
    title: "Peri Peri Cheese Fries",
    category: "Munchies",
    type: "Cheese Fries",
    Regular: 129,
    img: cheesefries3,
    desc: `A thin strip of potato, usually cut 3 to 4 inches in length and about 1/4 to 3/8 inches.`,
  },

  {
    id: 62,
    title: "Crispy Chicken Wings",
    category: "Munchies",
    type: "Chicken Snacks",
    Regular: 149,
    img: wings,
    desc: `A thin strip of potato, usually cut 3 to 4 inches in length and about 1/4 to 3/8 inches.`,
  },

  {
    id: 63,
    title: "Lemo Fizz",
    category: "Quencher",
    type: "5 Star Mojitos (Non Alcoholic) ",
    Regular: 49,
    img: juice01,
    desc: `The mojito is the epitome of the refreshing cocktail, stripped down to just the bare essentials of rum`,
  },
  {
    id: 64,
    title: "Mint Mojito",
    category: "Quencher",
    type: "5 Star Mojitos (Non Alcoholic) ",
    Regular: 69,
    img: juice02,
    desc: `The mojito is the epitome of the refreshing cocktail, stripped down to just the bare essentials of rum`,
  },
  {
    id: 65,
    title: "Passion Punch",
    category: "Quencher",
    type: "5 Star Mojitos (Non Alcoholic) ",
    Regular: 69,
    img: juice03,
    desc: `The mojito is the epitome of the refreshing cocktail, stripped down to just the bare essentials of rum`,
  },
  {
    id: 66,
    title: "Seashore",
    category: "Quencher",
    type: "5 Star Mojitos (Non Alcoholic) ",
    Regular: 69,
    img: juice04,
    desc: `The mojito is the epitome of the refreshing cocktail, stripped down to just the bare essentials of rum`,
  },
  {
    id: 67,
    title: "Twisted Berries",
    category: "Quencher",
    type: "5 Star Mocktail (Non Alcoholic) ",
    Regular: 69,
    img: juice05,
    desc: `The mojito is the epitome of the refreshing cocktail, stripped down to just the bare essentials of rum`,
  },

  {
    id: 68,
    title: "Hawaiian Blue",
    category: "Quencher",
    type: "5 Star Mocktail (Non Alcoholic) ",
    Regular: 89,
    img: juice06,
    desc: `The mojito is the epitome of the refreshing cocktail, stripped down to just the bare essentials of rum`,
  },
  {
    id: 69,
    title: "Tropical Sunset",
    category: "Quencher",
    type: "5 Star Mocktail (Non Alcoholic) ",
    Regular: 89,
    img: juice07,
    desc: `The mojito is the epitome of the refreshing cocktail, stripped down to just the bare essentials of rum`,
  },
  {
    id: 70,
    title: "Minty Berries",
    category: "Quencher",
    type: "5 Star Mojitos (Non Alcoholic) ",
    Regular: 89,
    img: juice08,
    desc: `The mojito is the epitome of the refreshing cocktail, stripped down to just the bare essentials of rum`,
  },

  {
    id: 71,
    title: "Cold Coffee",
    category: "Quencher",
    type: "Super Star Cold Coffees",
    Regular: 89,
    img: juice09,
    desc: `Super Star Cold Coffees`,
  },
  {
    id: 72,
    title: "Cold Coffee with Ice Cream",
    category: "Quencher",
    type: "Super Star Cold Coffees",
    Regular: 119,
    img: juice13,
    desc: `Super Star Cold Coffees`,
  },

  {
    id: 73,
    title: "Mango Mojo",
    category: "Quencher",
    type: "Coolest Shakes",
    Regular: 89,
    img: juice11,
    desc: `A milkshake is a sweet drink made by blending milk, ice cream,`,
  },
  {
    id: 74,
    title: "Sitafal Mojo",
    category: "Quencher",
    type: "Coolest Shakes",
    Regular: 89,
    img: juice10,
    desc: `A milkshake is a sweet drink made by blending milk, ice cream,`,
  },
  {
    id: 75,
    title: "Strawberry Mojo",
    category: "Quencher",
    type: "Coolest Shakes",
    Regular: 89,
    img: juice14,
    desc: `A milkshake is a sweet drink made by blending milk, ice cream,`,
  },
  {
    id: 76,
    title: "Chocolate",
    category: "Quencher",
    type: "Coolest Shakes",
    Regular: 89,
    img: juice13,
    desc: `A milkshake is a sweet drink made by blending milk, ice cream,`,
  },
  {
    id: 77,
    title: "Oreo",
    category: "Quencher",
    type: "Coolest Shakes",
    Regular: 89,
    img: juice12,
    desc: `A milkshake is a sweet drink made by blending milk, ice cream,`,
  },
  {
    id: 78,
    title: "Berries Blast",
    category: "Quencher",
    type: "Coolest Shakes",
    Regular: 89,
    img: juice14,
    desc: `A milkshake is a sweet drink made by blending milk, ice cream,`,
  },

  {
    id: 79,
    title: "Hot Chocolate Brownie",
    category: "Sweet Tooth",
    type: "Hot Chocolate Brownie(Eggless)",
    Regular: 79,
    img: St1,
    desc: `Our hot chocolate is made of brownies, home made chocolate sauce and vanilla ice-cream.`,
  },
  {
    id: 79,
    title: "Sizzling Brownie with icecream",
    category: "Sweet Tooth",
    type: "Hot Chocolate Brownie(Eggless)",
    Regular: 79,
    img: St1,
    desc: `Our hot chocolate is made of brownies, home made chocolate sauce and vanilla ice-cream.`,
  },
  {
    id: 80,
    title: "Kids Pizza Combo",
    category: "Combos",
    type: "Kids Pizza Combo",
    Regular: 129,
    img: CM1,
    desc: `4 in 1 Regular Pizza
    (1Pcs) + Small Fries
    + Mazza/Amul Tru`,
  },
  {
    id: 81,
    title: "Veg Pizza Meal for 1",
    category: "Combos",
    type: "Veg Pizza Meal Combos",
    Regular: 225,
    img: CM1,
    desc: `Any Regular Veg Pizza
    2 Pcs Garlic Bread
    1 Coke, 1 Cup Ice Cream`,
  },
  {
    id: 81,
    title: "Veg Pizza Meal for 2",
    category: "Combos",
    type: "Veg Pizza Meal Combos",
    Regular: 425,
    img: CM1,
    desc: `Any Veg Medium Pizza
    4 Pcs Garlic Bread
    2 Coke, 2 Cup Ice Cream`,
  },
  {
    id: 81,
    title: "Veg Pizza Meal for 4",
    category: "Combos",
    type: "Veg Pizza Meal Combos",
    Regular: 725,
    img: CM1,
    desc: `Any 2 Medium Pizza
    8 Pcs Garlic Bread
    4 Coke, 4 Cup Ice Cream`,
  },
  {
    id: 82,
    title: "Chicken Pizza Meal for 1",
    category: "Combos",
    type: "Chicken Pizza Meal Combos",
    Regular: 259,
    img: CM1,
    desc: `Any Regular Non Veg Pizza
    2 Pcs Garlic Bread
    1 Coke, 1 Cup Ice Cream`,
  },
  {
    id: 82,
    title: "Chicken Pizza Meal for 2",
    category: "Combos",
    type: "Chicken Pizza Meal Combos",
    Regular: 479,
    img: CM1,
    desc: `Any Non Veg Medium Pizza
    4 Pcs Garlic Bread
    2 Coke, 2 Cup Ice Cream`,
  },
  {
    id: 82,
    title: "Chicken Pizza Meal for 4",
    category: "Combos",
    type: "Chicken Pizza Meal Combos",
    Regular: 799,
    img: CM1,
    desc: `Any 2 Medium Pizza
    8 Pcs Garlic Bread
    4 Coke, 4 Cup Ice Cream`,
  },
];

export { type };

export default menu;
function type(category) {
  const typelist = new Set([]);
  menu.map((data) => {
    return data.category === category ? typelist.add(data.type) : 0;
  });
  return [...typelist];
}
