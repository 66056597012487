import React from "react";
import "./Home.css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import cheezosbanner from "../assets/CheezosBannerUncleAunty.png";
import cheezosbanner02 from "../assets/footerbanner.png";
import { Link } from "react-router-dom";
import menu from "../assets/menu.png";
import chef from "../assets/chef.png";
import telephone from "../assets/telephone.png";
import Offer from "../Offer/Offer";
import TodaysMenu from "../TodaysMenu/TodaysMenu";
import OurStory from "../OurStory/OurStory";
import slickslide01 from "../assets/banner Chizos.png";
import displayimage from "../assets/displayimage.png";
import HomeMenu from "../Homemenu/HomeMenu";
import banner2 from "../assets/CHIZOOS 12.png";
import banner3 from "../assets/Burger King Like Cover Pages 1 (1).png";
import banner4 from "../assets/banner Chizos.png";
import viewmenu from "../assets/viewmenu.jpg";
import aboutus from "../assets/aboutusbanner.png";
import RowImage01 from "../assets/revisedbanner01.png";
import RowImage02 from "../assets/revisedbanner02.png";
import MobileCarouselImage01 from "../assets/MobileCarouselImage01.jpeg";
import MobileCarouselImage02 from "../assets/MobileCarousel02.png";
import MobileCarouselImage03 from '../assets/MobileCarousel03.png'
export default function Home() {
  return (
    <div className="Home-Container">
      <div className="Home-Carousel">
        <Carousel
          autoPlay={true}
          showThumbs={false}
          infiniteLoop={true}
          interval={2000}
          showArrows={false}
          showStatus={false}
          preventMovementUntilSwipeScrollTolerance={true}
        >
          {/* <div className="Home-Carousel-Slides">
            <img src={displayimage} alt="" />
          </div> */}
          <div className="Home-Carousel-Slides">
            <img src={banner2} alt="" />
          </div>
          <div className="Home-Carousel-Slides">
            <img src={slickslide01} alt="" />
          </div>
          {/* <div className="Home-Carousel-Slides">
            <img src={banner3} alt="" />
          </div> */}
          <div className="Home-Carousel-Slides">
            <img src={banner3} alt="" />
          </div>

          <div className="Home-Carousel-Slides1">
            <img src={aboutus} alt="" />
          </div>
        </Carousel>
      </div>
      <div className="Mobile-Carousel">
        <Carousel
          showThumbs={false}
          showArrows={true}
          showStatus={false}
          showIndicators={false}
          preventMovementUntilSwipeScrollTolerance={true}
        >
          <div>
          <img src={MobileCarouselImage03 } alt="" />
          </div>
          <div>
            <img src={MobileCarouselImage02} alt="" />
          </div>
          <div>
          <img src={MobileCarouselImage01} alt="" />
          </div>
        </Carousel>
      </div>

      <div className="Mobile-OrderNow">
        <a
          href="https://thrivenow.in/thecheezos"
          target="_blank"
          rel="noreferrer"
          className="Mobile-OrderNowBTN"
        >
          Order Now
        </a>
      </div>
      <HomeMenu />
      <div className="Home-Introduction">
        <div className="Header">
          <span></span>
          <h2>Welcome To Cheezos</h2>
          <span></span>
        </div>
        <div className="Home-Introduction-Content">
          <div className="Home-Introduction-Content-Row">
            {" "}
            <p>
              An unforgettable trip to a world of real Italian flavour,
              delectable cuisine, and mouth-watering treats. At Cheezos, our
              goal is to provide the best Italian cuisine in town so that
              Indians can taste wonderful pizza like you have never experienced
              before. Each dish is handcrafted to perfection by our world-class
              chefs, and you'll fall in love with the intoxicating aroma,
              delicious flavour, and zingy zest from the first mouthful..We
              serve best in class cuisines from indian to italian. We as a team
              Cheezos Welcomes you to have a great time at Cheezos and enjoy
              every moment with you family.
            </p>
            <div className="Row-Image">
              <img src={RowImage01} alt="" />
            </div>
          </div>
          <div className="Home-Introduction-Content-Row">
            <div className="Row-Image-02">
              <img src={RowImage02} alt="" />
            </div>
            <p>
              Our star dish, Wraps and Rolls, is the specialty best paired with
              the pizza, which we create with passion. Our menu also includes
              some delectable appetizers, pasta, and a few beverages, ensuring
              that you have a comprehensive and satisfying meal. Your perfect
              choice from Best pizza near you.Every taste bud is unique from
              other like our every pizza topping is unique from other. That’s
              why we believe in “Every Bite Creates A New Story”.We as a team
              Cheezos Welcomes you to have a great time at cheezos and enjoy
              every moment with you family.
            </p>
          </div>
          <div className="Introduction-tagline">
            <h2>“Every Bite Creates A New Story”</h2>
          </div>
        </div>
      </div>
      <div className="OurStory">
        <OurStory />
      </div>

      <div className="Complete-view-menu">
        <div className="Complete-view-menu-Header">
          <h2>Quick View Menu</h2>
        </div>
        <img src={viewmenu} alt="" />
      </div>
      <div className="TodaysMenu-Cards">
        <TodaysMenu />
      </div>
      {/* <div className="Home-Product-Card">
        <div className="Product-Card-Header">
          <span></span>
          <h2>What We Serve !!</h2>
          <span></span>
        </div>
        <div className="Cards">
          <div className="Product-Card">
            <div className="Product-Image">
              <img src={whatweserve01} alt="" />
            </div>
            <div className="Product-Content">
              <h2>Pizzeria</h2>
              <span></span>
              <p>We Serve-</p>
              <ul>
                <li>Cheezonly Pizza</li>
                <li>Veggies Pizza</li>
                <li>Paneer Pizza</li>
                <li>Chicken Pizza</li>
                <li>UNO Pizza</li>
              </ul>
              <Link to="/menu" className="Product-Card-Btn">
                See More
                <PlayArrow />
              </Link>
            </div>
          </div>
          <div className="Product-Card">
            <div className="Product-Image">
              <img src={whatweserve02} alt="" />
            </div>
            <div className="Product-Content">
              <h2>Breads</h2>
              <span></span>
              <p>We Serve-</p>
              <ul>
                <li>Garlic Bread</li>
                <li>Stuffed Garlic Bread</li>
              </ul>
              <Link to="/menu" className="Product-Card-Btn">
                See More
                <PlayArrow />
              </Link>
            </div>
          </div>
          <div className="Product-Card">
            <div className="Product-Image">
              <img src={whatweserve03} alt="" />
            </div>
            <div className="Product-Content">
              <h2>Pasta</h2>
              <span></span>
              <p>We Serve-</p>
              <ul>
                <li>Arrabbiata Sauce Pasta</li>
                <li>Bechamel Cream Sauce Pasta</li>
                <li>Marinara Sauce Pasta</li>
                <li>Marinara Cream Sauce Pasta</li>
              </ul>
              <Link to="/menu" className="Product-Card-Btn">
                See More
                <PlayArrow />
              </Link>
            </div>
          </div>
          <div className="Product-Card">
            <div className="Product-Image">
              <img src={whatweserve04} alt="" />
            </div>
            <div className="Product-Content">
              <h2>Flat Breads</h2>
              <span></span>
              <p>We Serve-</p>
              <ul>
                <li>Tortilla Wraps</li>
                <li>Lachha Rolls</li>
              </ul>
              <Link to="/menu" className="Product-Card-Btn">
                See More
                <PlayArrow />
              </Link>
            </div>
          </div>
          <div className="Product-Card">
            <div className="Product-Image">
              <img src={whatweserve05} alt="" />
            </div>
            <div className="Product-Content">
              <h2>Munchies</h2>
              <span></span>
              <p>We Serve-</p>
              <ul>
                <li>Nachos</li>
                <li>Fries</li>
                <li>CHEESY FRIES</li>
                <li>Chicken Snacks</li>
              </ul>
              <Link to="/menu" className="Product-Card-Btn">
                See More
                <PlayArrow />
              </Link>
            </div>
          </div>
          <div className="Product-Card">
            <div className="Product-Image">
              <img src={whatweserve06} alt="" />
            </div>
            <div className="Product-Content">
              <h2>Quenchers</h2>
              <span></span>
              <p>We Serve-</p>
              <ul>
                <li>5 Star Mojitos (Non Alcoholic)</li>
                <li>5 Star Mocktail (Non Alcoholic)</li>
                <li>Super Star Cold Coffees</li>
                <li>Coolest Shakes</li>
              </ul>
              <Link to="/menu" className="Product-Card-Btn">
                See More
                <PlayArrow />
              </Link>
            </div>
          </div>
          <div className="Product-Card">
            <div className="Product-Image">
              <img src={whatweserve07} alt="" />
            </div>
            <div className="Product-Content">
              <h2>Sweet Tooth</h2>
              <span></span>
              <p>We Serve-</p>
              <ul>
                <li>Hot Chocolate Brownie(Egg less)</li>
              </ul>
              <Link to="/menu" className="Product-Card-Btn">
                See More
                <PlayArrow />
              </Link>
            </div>
          </div>
          <div className="Product-Card">
            <div className="Product-Image">
              <img src={whatweserve01} alt="" />
            </div>
            <div className="Product-Content">
              <h2>Combo</h2>
              <span></span>
              <p>We Serve-</p>
              <ul>
                <li>
                  Kids Pizza Combo (One 4 in1 regular pizza along with 1 small
                  fries & choice of drink Amul Tru (Mango/Apple/Orange/Lychee))
                </li>
              </ul>
              <Link to="/menu" className="Product-Card-Btn">
                See More
                <PlayArrow />
              </Link>
            </div>
          </div>
        </div>
      </div> */}

      <div className="Offer">
        <Offer />
      </div>

      <div className="Features">
        <div className="Features-Header">
          <span></span>
          <h2>Why People Choose Cheezos ?</h2>
          <span></span>
        </div>
        <div className="Features-Row">
          <div className="Feature-Card">
            <div className="Card-Icon">
              <img src={menu} alt="" />
            </div>
            <div className="Card-Content">
              <h3>Comprehensive Menu </h3>
              <p>
                A feeling of peace and fulfillment he was attempting to
                reproduce when we took a little piece of the ‘Cheezos and
                thudded it down right in the territory of Central India. Phone
                Reservation
              </p>
            </div>
          </div>

          <div className="Feature-Card">
            <div className="Card-Icon">
              <img src={telephone} alt="" />
            </div>
            <div className="Card-Content">
              <h3>Phone Reservations</h3>
              <p>
                Phone reservations and experienced customer service. One quick
                call to have your favourite meal whenever you want. We are only
                one call away.{" "}
              </p>
            </div>
          </div>

          <div className="Feature-Card">
            <div className="Card-Icon">
              <img src={chef} alt="" />
            </div>
            <div className="Card-Content">
              <h3>Experienced Chefs</h3>
              <p>
                Despite everything, we hold fast to the fundamental rationality
                that new sustenance, well-disposed administration, and a
                laid-back air is the ideal end to a hard day of work or play. We
                trust you taste it in each chomp.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
