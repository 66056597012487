import React, { useState } from "react";
import "./Navbar.css";
import logo from "../assets/Cheezos Logo 1.svg";
import { Link } from "react-router-dom";
import {
  Collections,
  Facebook,
  Forum,
  Home,
  Info,
  Instagram,
  Message,
  PhoneAndroid,
  RestaurantMenu,
  WhatsApp,
} from "@material-ui/icons";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { Menu, X } from "react-feather";
import { FoodBank } from "@mui/icons-material";
import cheezosmenu from "../assets/menu.pdf";
import instagram from '../assets/instagram.png'
import facebook from '../assets/facebook.png'
import message from '../assets/message.png'
export default function Navbar() {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <>
      <div className="Navbar">
        <div className="navbar-icon">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="Menu-link">
          <div className="nav-link">
            <Link to="/">
              {" "}
              <Home />
              Home
            </Link>
            <Link to="/aboutUs">
              <Info />
              About Us
            </Link>
            <Link to="/NewMenu">
              <RestaurantMenu />
              Menu
            </Link>
            <Link to="/gallery">
              <Collections />
              Gallery
            </Link>
            <Link to="/Franchise">
              <FoodBank />
              Franchise
            </Link>
            <Link to="/feedback">
              <Forum />
              Contact Us
            </Link>
          </div>

          <div className="nav-btn">
            <a
              href="https://thrivenow.in/thecheezos"
              target="_blank"
              rel="noreferrer"
              className="nav-button"
            >
              Order Now
            </a>
          </div>
        </div>
        <button
          onClick={() => {
            setShowMenu(true);
          }}
          className="Sidebar-menu"
        >
          <Menu />
        </button>
      </div>
      {showMenu && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, x: "+10vw" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, x: "+10vw" }}
            className="sideBar"
          >
            <div
              onClick={() => {
                setShowMenu(false);
              }}
              className="empty"
            ></div>

            <div className="links">
              <div className="top">
                <button
                  onClick={() => {
                    setShowMenu(false);
                  }}
                  className="Side-menu-close"
                >
                  <X />
                </button>
              </div>
              <Link to="/">
                {" "}
                <Home />
                Home
              </Link>
              <Link to="/aboutUs">
                <Info />
                About Us
              </Link>
              <Link to="/NewMenu">
                <RestaurantMenu />
                Menu
              </Link>
              <Link to="/gallery">
                <Collections />
                Gallery
              </Link>
              <Link to="/Franchise">
                <FoodBank />
                Franchise
              </Link>
              <Link to="/feedback">
                <Forum />
                Contact Us
              </Link>
              <a
                href="https://thrivenow.in/thecheezos"
                target="_blank"
                rel="noreferrer"
                className="nav-button"
              >
                Order Now
              </a>
              <a className="phone-link" href="tel:+91 7049027777">
                {" "}
                <PhoneAndroid />
                +91 7049027777
              </a>
              
              <a
                className="Menu-btn"
                target="_blank"
                rel="noreferrer"
                href={cheezosmenu}
              >
                Get Franchise
              </a>
              <a
                className="Menu-btn"
                target="_blank"
                rel="noreferrer"
                href={cheezosmenu}
              >
                Get Menu
              </a>
              <div className="Social-Media-Row">
                <div className="Instagram-Div">
                  <a
                    href="https://instagram.com/thecheezos?igshid=YmMyMTA2M2Y="
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {" "}
                    <img src={ instagram} alt="" />
                  </a>
                </div>
                <div className="Facebook-Div">
                  <a
                    href="https://m.facebook.com/The-Cheezos-114197131264623/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={facebook} alt="" />
                  </a>
                </div>
                <div className="Franchise-Div">
                  <Link to="/feedback">
                    {" "}
                    <img src={message} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
}
