import React from "react";
import "./Capsule.css";
export default function Capsule(props) {
  return (
    <div className="Capsule-Container">
      <span></span>
      <p>{props.children}</p>
    </div>
  );
}
