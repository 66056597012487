import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomeMenu.css";
import slickburger from "../assets/slickburger.svg";
import homepizza from "../assets/homepizza.png";
import homebreads from "../assets/homebreads.png";
import homepasta from "../assets/homepasta.png";
import homeflat from "../assets/homeflatbreads.png";
import homemunchies from "../assets/homemunchies.png";
import homequencher from "../assets/homequencher.png";
import homebrownie from "../assets/homebrownie.png";
import homecombos from "../assets/homecombos.png";
import { Link } from "react-router-dom";
export default function SimpleSlider() {
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 4,
    arrows: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          dots: false,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 780,
        settings: {
          dots: false,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="Home-Menu-Container">
      <div className="Home-Menu-Slider">
        <Slider {...settings}>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homepizza} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Pizzeria</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homebreads} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Garlic Breads</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homepasta} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Pasta</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homeflat} alt=""/>
              </div>
              <div className="Slick-Slide-Title">
                <p>Flat Breads</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homemunchies} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Munchies</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homequencher} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Quencher</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homebrownie} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Sweet Tooth</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homecombos} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Combos</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homebreads} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Garlic Breads</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homepasta} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Pasta</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homeflat} alt=""/>
              </div>
              <div className="Slick-Slide-Title">
                <p>Flat Breads</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homemunchies} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Munchies</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homequencher} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Quencher</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homebrownie} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Sweet Tooth</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homecombos} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Combos</p>
                <span></span>
              </div>
            </div>
          </Link>
          {/* <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homecombos} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Combos</p>
                <span></span>
              </div>
            </div>
          </Link> */}
          {/* <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homecombos} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Combos</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homecombos} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Combos</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homecombos} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Combos</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homecombos} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Combos</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homecombos} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Combos</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homecombos} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Combos</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homecombos} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Combos</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homecombos} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Combos</p>
                <span></span>
              </div>
            </div>
          </Link>
          <Link to="/NewMenu">
            <div className="Home-Menu-Slides">
              <div className="Slick-Slide-Image">
                <img src={homecombos} alt="" />
              </div>
              <div className="Slick-Slide-Title">
                <p>Combos</p>
                <span></span>
              </div>
            </div>
          </Link> */}
        </Slider>
      </div>
    </div>
  );
}
