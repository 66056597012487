import { FoodBank } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'
import './Form-Trigger.css'
export default function FormTrigger() {
  return (
    <div className='Form-Trigger'>
        <div className="Trigger-Button">
        <Link to='/Franchise' className='Form-Trigger-btn'><FoodBank/>Get Franchise</Link>
        </div>
    </div>
  )
}
