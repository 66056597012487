import React, { useEffect, useState } from 'react'
import { ChevronDown } from 'react-feather';
import './DropDown.css'
export default function DropDown(props) {
    let {label,links, setState} = props;
   const[show,setShow]=  useState(false);
   useEffect(() => {
    if(show){
      let documentClick = document.body.addEventListener('click',close,true)
    }  
    
      
    return () => {
      document.body.removeEventListener('click',close,true)
    };
  }, [show])
  function close(){
      document.body.removeEventListener('click',close,true)
      console.log('closing')
      setShow(false);
  }
    return (
        <div className="dropdown">
            <div className="toggler" onClick={()=>{setShow(!show)}}>
                {label}
                <ChevronDown/>
            </div>
            {show&&<div className="dropmenu" aria-labelledby="dropdownMenuLink">
                {links.map((link,index)=> <><button onMouseDown={()=>setState({category:label,type:link})} key={index}>{link}</button><span></span></> )}
            </div>}
        </div>
    )
}
