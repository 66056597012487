import React from 'react'
import './SplashScreen.css'
import loadinglogo from '../assets/loadinglogo.svg'
import LoadingIcon from '../LoadingIcon/LoadingIcon'

export default function SplashScreen() {
  return (
 <div className='SplashScreen'>
                <div className="LoadingScreen">
                    <img src={loadinglogo} alt="" />
                </div>
                <div className="LoadingIcon">
                    <LoadingIcon/>
                </div>
    </div>

  )
}
