import React from "react";
import "./FranchisePage.css";
import Logo from "../assets/Logo.png";
export default function FranchisePage() {
  const HandleSubmit = (e) => {

    e.preventDefault();
    const scriptURL = `https://v1.nocodeapi.com/thecheezos/google_sheets/STuXRKDRGcGyOqwv?tabId=Franchise`;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const form = document.forms['Franchise-Form']
    let data = new FormData(form)
    let row = [
      [data.get("Name"), data.get("Email"), data.get("MobileNo"), data.get("State"), data.get("City"), data.get("Address"), data.get("Message")]
    ]

    console.log(row)
    var requestOptions = {
      method: "post",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(row)
    };


    //console.log(data.get("name"));
    fetch(scriptURL, requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log(result);      
        alert('Your Query Was Sent Successfully!');
        window.location.reload();
      })
      .catch(error => {

        alert('Failed to send your query.!');
        console.log('error', error)
      });
  }
  return (
    <div className="Franchise-Container">
      <div className="Franchise-Form-Container">
        <div className="Franchise-Form-Banner">
          <img src={Logo} alt="" />
        </div>
        <div className="Franchise-Form">
          <form name="Franchise-Form" action="submit" onSubmit={HandleSubmit} >
            <div className="Franchise-Form-Header">
              <p>Request Your Interest</p>
            </div>
            <div className="Franchise-Form-Row">
              <input type="text" name="Name" id="ID-01" placeholder="Name" required />
              <input type="email" name="Email" id="ID-02" placeholder="Email" required />
            </div>
            <div className="Franchise-Form-Row">
              <input
                type="text"
                name="MobileNo"
                id="ID-03"
                placeholder="Mobile Number"
                required />
              <input type="text" name="State" id="ID-04" placeholder="State" required />
            </div>
            <div className="Franchise-Form-Row">
              <input type="text" name="City" id="ID-05" placeholder="City" required />
              <input
                type="text"
                name="Address"
                id="ID-06"
                placeholder="Address"
                required />
            </div>

            <div className="Franchise-Form-Message-Row">
              <textarea
                placeholder="Message"
                name="Message"
                id="ID-07"
                cols="30"
                rows="10"
                required
              ></textarea>
            </div>

            <div className="Franchise-Form-Button">
              <button className="Franchise-Form-BTN">Submit</button>
            </div>
          </form>


        </div>
      </div>
    </div>
  );
}
