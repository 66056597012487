import { MenuOpen } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import "./NewMenu.css";
import DropDown from "../Menu-dropdown/DropDown";
import Data, { type } from "../../Data";
import items from "../../Data";
import viewmenu from "../assets/viewmenu.jpg";
import image01 from "../NewmenuAssets/01.jpg";
import image02 from "../NewmenuAssets/02.jpg";
import image03 from "../NewmenuAssets/03.jpg";
import image04 from "../NewmenuAssets/04.jpg";
import image05 from "../NewmenuAssets/05.jpg";
import image06 from "../NewmenuAssets/06.jpg";
import image07 from "../NewmenuAssets/07.jpg";
import image08 from "../NewmenuAssets/08.jpg";
import Capsule from "../CategoryCapsule/Capsule";
import Newmenu from "../../NewData";
import HeadingCapsule from "../CapsuleHeading/HeadingCapsule";
import { X } from "react-feather";
const allCategories = [...new Set(items.map((item) => item.category))];
export default function NewMenu({ filterItems, activeCategory }) {
  const [filter, setfilter] = useState({ category: "", type: "" });
  const [menuItems, setMenuItems] = useState(Newmenu);
  const [categories, setCategories] = useState(allCategories);
  useEffect(() => {
    if (filter.type.length > 0) {
      scrollToTargetAdjusted(filter.type.replace(/ /g, ""));
    }
    console.log(filter);
    return () => {};
  }, [filter]);

  // useEffect(() => {
  //   if (filter.length > 0) {
  //     const Filter = Newmenu.filter((x) => x.Category === filter);
  //     setMenuItems(Filter);
  //     console.log(Filter, filter);
  //   }
  // }, [filter]);
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="NewMenu-Container">
      <div className="NewMenu-Side-Bar-Container">
        <div className="Menu-Sidebar">
          <div className="Menu-Sidebar-Row">
            {categories.map((category, index) => {
              return (
                <div className="NewMenu-BTNS">
                  <DropDown
                    label={category}
                    links={type(category)}
                    setState={setfilter}
                    className={`${
                      activeCategory === allCategories
                        ? "filter-btn active"
                        : "filter-btn"
                    }`}
                    key={index}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="NewMenu-Banner">
        <div className="PDFImages">
          <img src={image01} alt="" />
          <img src={image02} alt="" />
          <img src={image03} alt="" />
          <img src={image04} alt="" />
          <img src={image05} alt="" />
          <img src={image06} alt="" />
          <img src={image07} alt="" />
          <img src={image08} alt="" />
        </div>
      </div>
      <div className="MappingDiv">
        {Newmenu.map((Category, index) => {
          return (
            <div className="Header-Categories">
              <HeadingCapsule>{Category.Category}</HeadingCapsule>
              {Category.SubCat.map((SubCat) => {
                return (
                  <div
                    className="Capsule-Div"
                    id={SubCat.name.replace(/ /g, "")}
                  >
                    <Capsule>{SubCat.name}</Capsule>
                    <div className="Menu-Page-Cards-Container">
                      {SubCat.menu.map((item, index) => (
                        <MenuCards {...item} />
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      <div className="New-Mobile-Menu-Trigger">
        <button
          className="New-Mobile-Menu-Trigger-BTN"
          onClick={() => {
            setShowMenu(true);
          }}
        >
          <MenuOpen /> Menu
        </button>
      </div>
      {showMenu && (
        <div className="NewMenu-Mobile-View-container">
          <div className="New-Menu-top">
            <button
              onClick={() => {
                setShowMenu(false);
              }}
              className="NewSide-menu-close"
            >
              <X />
            </button>
          </div>
          <div className="NewMenu-Mobile-Menu">
            {categories &&
              categories.map((category, index) => {
                return (
                  <DropDown
                    label={category}
                    links={type(category)}
                    setState={setfilter}
                    className={`${
                      activeCategory === allCategories
                        ? "filter-btn active"
                        : "filter-btn"
                    }`}
                    key={index}
                  />
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}
function MenuCards(props) {
  const { img, title, desc, Regular, Medium } = props;
  return (
    <div className="Cards-Container">
      <div className="NewMenu-Card">
        <div className="NewMenu-Card-Image">
          {" "}
          <img src={img} alt="" />
        </div>
        <div className="NewMenu-Card-Content">
          <div className="NewMenu-Card-NameTag-row">
           <p>{title}</p>
          </div>
          <div className="NewMenu-Card-Desc-Price-row">
            <p>{desc}</p>
            <div className="Price-Col">
              <div className="Price-Row">
                <p>Regular:</p>
                {Regular ? <p>₹{Regular}</p> : "N/A"}
              </div>
              <div className="Price-Row">
                <p>Medium:</p>
                {Medium ? <p>₹{Medium}</p> : " N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function scrollToTargetAdjusted(id) {
  var element = document.getElementById(id);
  console.log(element, id);
  var headerOffset = 45;
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
}
