import React from "react";
import "./getmenu.css";
import { Download } from "react-feather";
import cheezosmenu from "../assets/Menu_ Dine In_compressed.pdf";
export default function Getmenu() {
  return (
    <div className="Get-Menu-Button">
      <div className="Menu-Button">
        <a
          className="Menu-btn"
          target="_blank"
          rel="noreferrer"
          href={cheezosmenu}
        >
          <Download />
          Get Menu
        </a>
      </div>
    </div>
  );
}
