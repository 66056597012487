import React from "react";
import "./AboutUs.css";
import RowImage01 from "../assets/au.jpeg";
import RowImage02 from "../assets/revisedbanner02.png";
import AboutUsBanner from "../assets/aboutusbanner.png";
export default function AboutUs() {
  return (
    <div className="AboutUs">
      <div className="about">
        <div className="about-row-1">
          <div className="AboutUs-Content-row">
            <div className="Aboutus-Header">
              <h2>Who Are We</h2>
            </div>

            <div className="row-1">
              <div className="AboutUs-Row">
                <p>
                  The Cheezos is a journey of a MNC manager of corporate world
                  to planning, designing & establishment of the Cheezos. It’s an
                  extreme hard work of a wife who led the kitchen day & night
                  for every dish finalization, taste correction, food packaging,
                  and act as both critics & admirer for maintaining the right
                  balance. After almost 4 years of hard core research, multiple
                  preparation & testing of dishes we are able to convert our
                  ideas into reality.We Serve best in class cuisines from indian
                  to italian. We as a team Cheezos Welcomes you to have a great
                  time at cheezos and enjoy every moment with you family.
                </p>
                <div className="AboutUs-Row-Image">
                  <img src={RowImage01} alt="" />
                </div>
              </div>
              <p>
                {" "}
                The Cheezos offer varieties of delicious food items for
                children, teenagers & adults. The Cheezos offer best food items
                for different taste buds, different cravings, different
                occasions & parties. Yes we are new in India but we are
                dedicated for delivering variety in menu, use of quality
                ingredients, use of eco friendly single serving’s products,
                hygienic preparation of food & serving fresh, tasty food for
                all. Our motive is to serve delicious food to our customers in
                India & across the globe.
              </p>
              {/* <div className="AboutusImage">
              <img src={aboutusimage} alt="" />
            </div> */}
            </div>
          </div>
        </div>
        <div className="AboutUs-Banner-Images">
          <img src={AboutUsBanner} alt="" />
        </div>
        {/* <div className="about-row-2">
        <div className="Aboutus-Header-2">
            <span></span>
            <h2>Meet Our Chefs</h2>
            <span></span>
          </div>
          <div className="row-2">
            <img src={ChefCard} alt="/" />
            <img src={ChefCard} alt="/" />
            <img src={ChefCard} alt="/" />
          </div>
        </div> */}
      </div>

      <div className="Content-Pizzeria">
        <div className="Content-Pizzeria-Header">
          <h2>About The Cheezos Pizzeria</h2>
        </div>
        <div className="Pizzeria-Row">
          <div className="Pizzeria-Row-Image">
            {" "}
            <img src={RowImage02} alt="" />
          </div>

          <p>
            The Cheezos pizza is not just any food it’s a craving for satisfying
            ones soul. Having fresh baked pizza is an experience which you can’t
            get at quick in & quick out serving places. It takes some time to
            stretch the dough some time to spread gourmet sauce, mozzarella
            cheese & toppings & little patience to perfectly bake it in an oven.
            This wait is worth for having an experience to taste The Cheezos hot
            & super tasty handcrafted fresh dough pizza.We Serve best in class
            cuisines from indian to italian. We as a team Cheezos Welcomes you
            to have a great time at cheezos and enjoy every moment with you
            family.
          </p>
        </div>
        <p>
          Children, teenagers & adults have different choices in food & keeping
          everyone in mind we design pizzas to satisfy every once craving. The
          Cheezonly pizzas for those who love Cheese the most, Veggies pizzas
          for vegetables toppings lovers, Paneer pizzas for Indian cottage
          cheese lovers & Chicken pizzas for chicken & cheese lovers. Every
          taste bud is unique from other like our every pizza topping is unique
          from other. That’s why we believe in “Every Bite Creates A New Story”.
        </p>
      </div>
    </div>
  );
}
